import apiClient from "./ApiClient.js";

export default {
  getAnkaeufe(params = {}) {
    return apiClient.get("/ankauf", { params });
  },

  getAnkauf(id) {
    return apiClient.get("/ankauf/" + id);
  },

  ankaufCount() {
    return apiClient.get("/ankauf-count");
  }
};
