import apiClient from "./ApiClient.js";

export default {
  eigenes(data) {
    return apiClient.post("/ankaufereignis/eigenes", data);
  },

  verschickt(data) {
    return apiClient.post("/ankaufereignis/verschickt", data);
  },

  angerufen(data) {
    return apiClient.post("/ankaufereignis/angerufen", data);
  },

  beauftragt(data) {
    return apiClient.post("/ankaufereignis/beauftragt", data);
  },

  eingang(data) {
    return apiClient.post("/ankaufereignis/eingang", data);
  },

  angebot(data) {
    return apiClient.post("/ankaufereignis/angebot", data);
  },

  bearbeiten(data) {
    return apiClient.post("/ankaufereignis/bearbeiten", data);
  },

  loeschen(data) {
    return apiClient.post("/ankaufereignis/loeschen", data);
  }
};
