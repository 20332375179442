<template>
  <div>
    <BaseDashboardContainer class="mb-10">
      <template v-slot:header>
        <h4>Angebot</h4>
        <p v-if="!ankauf.angebote.length" class="text-gray-600">
          Aus der Analyse ergibt sich folgendes Angebot:
        </p>
      </template>
      <div v-if="!ankauf.angebote.length">
        <div class="mb-4 -mx-6 -mt-6">
          <table
            class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
          >
            <thead>
              <tr class="text-left">
                <BaseTh>Artikel</BaseTh>
                <BaseTh>Menge</BaseTh>
                <BaseTh>Einheit</BaseTh>
                <BaseTh>Preis</BaseTh>
                <BaseTh>Gesamtpreis</BaseTh>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(analyse, index) in ankauf.analysen" :key="index">
                <BaseTd>{{ analyse.gegenstand }}</BaseTd>
                <BaseTd>{{ parseFloat(analyse.menge) }}</BaseTd>
                <BaseTd>{{ analyse.einheit | ucfirst }}</BaseTd>
                <BaseTd>{{ analyse.preis | toCurrency }}</BaseTd>
                <BaseTd>{{ analyse.preis_gesamt | toCurrency }}</BaseTd>
              </tr>
            </tbody>
          </table>
        </div>
        <p>Netto: {{ auszahlungsbetragNetto }}</p>
        <p>Brutto: {{ auszahlungsbetragNetto }}</p>
        <p class="pt-2 mt-2 text-lg font-medium border-t">
          Aufgerundet: {{ ankauf.angebotSummeAnalyse | toCurrency }}
        </p>
      </div>
      <div v-if="ankauf.angebote.length">
        <div class="grid md:grid-cols-2 gap-y-10">
          <div
            v-for="(angebot, index) in ankauf.angebote"
            :key="index"
            class="px-4 border-l border-r"
            :class="{ 'opacity-50': index != 0 }"
          >
            <p v-if="index == 0 && ankauf.status == 'aktiv'">
              Aktuelles Angebot:
            </p>
            <p v-else-if="index == 0 && ankauf.status != 'aktiv'">
              Angebot:
            </p>
            <p v-else>Vorheriges Angebot:</p>
            <h4 class="mb-3">{{ angebot.angebot | toCurrency }}</h4>
            <p class="mb-1">
              <span class="font-medium">Status: </span>
              <span v-if="angebot.akzeptiert === null" class="text-gray-600"
                >-</span
              >
              <span v-if="angebot.akzeptiert == true" class="text-green-600"
                >akzeptiert</span
              >
              <span v-if="angebot.akzeptiert == false" class="text-red-600"
                >abgelehnt</span
              >
            </p>
            <p v-if="angebot.auszahlung_medium" class="mb-1">
              <span class="font-medium">Auszahlungsmedium: </span>
              <span>{{ angebot.medium_name }}</span>
            </p>
            <p v-if="angebot.medium_account" class="mb-1">
              <span
                v-if="angebot.auszahlung_medium == 'ueberweisung'"
                class="font-medium"
                >IBAN:
              </span>
              <span
                v-if="angebot.auszahlung_medium == 'paypal'"
                class="font-medium"
                >PayPal Adresse:
              </span>
              <span>{{ angebot.medium_account }}</span>
            </p>
            <div v-if="angebot.auszahlung_medium == 'scheck'">
              <p class="mt-3 text-gray-600">Scheck senden an:</p>
              <p>
                {{ angebot.scheck_adresse.firma
                }}{{ angebot.scheck_adresse.vorname }}
                {{ angebot.scheck_adresse.nachname }}
              </p>
              <p>
                {{ angebot.scheck_adresse.strasse }}
                {{ angebot.scheck_adresse.hausnummer }}
              </p>
              <p>
                {{ angebot.scheck_adresse.plz }}
                {{ angebot.scheck_adresse.ort }}
              </p>
            </div>
            <p v-if="angebot.abgelehnt_grund" class="mt-4 mb-1">
              <span class="font-medium">Ablehnungsgrund:</span><br />
              <span class="text-gray-600">{{ angebot.abgelehnt_grund }}</span>
            </p>
            <p v-if="angebot.nachricht" class="mt-4 mb-1">
              <span class="font-medium">Nachricht:</span><br />
              <span class="text-gray-600">{{ angebot.nachricht }}</span>
            </p>
          </div>
        </div>
        <div
          v-if="ankauf.status == 'aktiv'"
          class="grid gap-4 pt-6 mt-10 border-t md:grid-cols-3"
        >
          <div>
            <label
              for="ankauf_id"
              class="block mb-1 text-sm font-medium text-gray-600"
              >Auszahlungsbetrag</label
            >
            <BaseInput
              v-currency
              v-model="formAbschliessen.betrag"
              id="ankauf_id"
              class="w-full h-11"
              :class="{
                'border-red-500': $v.formAbschliessen.betrag.$error
              }"
            />
          </div>
          <div>
            <label
              for="auszahlungsmedium"
              class="block mb-1 text-sm font-medium text-gray-600"
              >Ausgezahlt per</label
            >
            <BaseSelect
              v-model="formAbschliessen.medium"
              id="auszahlungsmedium"
              class="w-full h-11"
            >
              <option value="ueberweisung">Überweisung</option>
              <option value="paypal">PayPal</option>
              <option value="scheck">Scheck</option>
            </BaseSelect>
          </div>
          <div v-if="formAbschliessen.medium == 'ueberweisung'">
            <label
              for="iban"
              class="block mb-1 text-sm font-medium text-gray-600"
              >IBAN</label
            >
            <BaseInput
              v-model="formAbschliessen.iban"
              id="iban"
              class="w-full h-11"
              :class="{
                'border-red-500': $v.formAbschliessen.iban.$error
              }"
            />
          </div>
          <div v-if="formAbschliessen.medium == 'paypal'">
            <label
              for="paypal"
              class="block mb-1 text-sm font-medium text-gray-600"
              >PayPal Adresse</label
            >
            <BaseInput
              v-model="formAbschliessen.paypal"
              id="paypal"
              class="w-full h-11"
              :class="{
                'border-red-500': $v.formAbschliessen.paypal.$error
              }"
            />
          </div>
          <div
            v-show="formAbschliessen.medium == 'scheck'"
            class="md:col-span-3"
          >
            <p class="mb-2 text-gray-600">Scheck senden an:</p>
            <div v-if="ankauf.angebote[0].scheck_adresse">
              <p>
                {{ ankauf.angebote[0].scheck_adresse.firma
                }}{{ ankauf.angebote[0].scheck_adresse.vorname }}
                {{ ankauf.angebote[0].scheck_adresse.nachname }}
              </p>
              <p>
                {{ ankauf.angebote[0].scheck_adresse.strasse }}
                {{ ankauf.angebote[0].scheck_adresse.hausnummer }}
              </p>
              <p>
                {{ ankauf.angebote[0].scheck_adresse.plz }}
                {{ ankauf.angebote[0].scheck_adresse.ort }}
              </p>
            </div>
            <div v-else>
              <p>
                {{ ankauf.adresse.firma }}{{ ankauf.adresse.vorname }}
                {{ ankauf.adresse.nachname }}
              </p>
              <p>
                {{ ankauf.adresse.strasse }}
                {{ ankauf.adresse.hausnummer }}
              </p>
              <p>
                {{ ankauf.adresse.plz }}
                {{ ankauf.adresse.ort }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div v-if="!ankauf.angebote.length" class="flex justify-end">
          <BaseButton
            @click.native="angebotSendenText"
            :loading="loadingTextAngebot"
            >Angebot senden</BaseButton
          >
        </div>
        <div
          v-if="ankauf.angebote.length && ankauf.status == 'aktiv'"
          class="grid gap-4 py-2 md:grid-cols-2"
        >
          <BaseButton
            :loading="loadingTextAbbrechen"
            @click.native="ankaufAbbrechenText"
            variant="white"
            >Ankauf abbrechen</BaseButton
          >
          <BaseButton
            class="order-first md:order-last"
            :loading="loadingTextAbschliessen"
            @click.native="ankaufAbschliessenText"
            >Ankauf abschließen</BaseButton
          >
        </div>
      </template>
    </BaseDashboardContainer>

    <!-- Modal Angebot senden -->
    <BaseModal :visible="showModalAngebot" @hide="hideModal">
      <div class="bg-white border border-gray-100 rounded-md shadow">
        <div
          class="flex justify-between px-6 py-2 border-b bg-gray-50 rounded-t-md"
        >
          <h5 class="py-2">Angebot senden</h5>
          <div class="flex items-center space-x-4">
            <p class="font-medium">E-Mail senden</p>
            <BaseToggle v-model="mailsent" />
          </div>
        </div>
        <div class="relative">
          <div
            v-show="!mailsent"
            class="absolute z-40 w-full h-full bg-white opacity-80"
          ></div>
          <div>
            <label class="block px-6 pt-2 text-sm font-medium">Betreff</label>
            <BaseInput class="w-full pl-6 border-none h-11" v-model="subject" />
          </div>
          <Editor ref="editor" :value="text" @input="saveMarkdown" />
        </div>
        <div class="px-6 py-2 bg-gray-50 rounded-b-md">
          <div class="flex justify-end py-2">
            <BaseButton @click.native="angebotSenden" :loading="loadingAngebot"
              >Angebot senden</BaseButton
            >
          </div>
        </div>
      </div>
    </BaseModal>
    <!-- Modal Angebot senden ENDE -->

    <!-- Modal Ankauf abschließen -->
    <BaseModal :visible="showModalAnkaufAbschliessen" @hide="hideModal">
      <div class="bg-white border border-gray-100 rounded-md shadow">
        <div
          class="flex justify-between px-6 py-2 border-b bg-gray-50 rounded-t-md"
        >
          <h5 class="py-2">Ankauf abschließen</h5>
          <div class="flex items-center space-x-4">
            <p class="font-medium">E-Mail senden</p>
            <BaseToggle v-model="mailsent" />
          </div>
        </div>
        <div class="relative">
          <div
            v-show="!mailsent"
            class="absolute z-40 w-full h-full bg-white opacity-80"
          ></div>
          <div>
            <label class="block px-6 pt-2 text-sm font-medium">Betreff</label>
            <BaseInput class="w-full pl-6 border-none h-11" v-model="subject" />
          </div>
          <Editor ref="editor" :value="text" @input="saveMarkdown" />
        </div>
        <div class="px-6 py-2 bg-gray-50 rounded-b-md">
          <div class="flex justify-end py-2">
            <BaseButton
              @click.native="ankaufAbschliessen"
              :loading="loadingAbschliessen"
              >Ankauf abschließen</BaseButton
            >
          </div>
        </div>
      </div>
    </BaseModal>
    <!-- Modal Ankauf abschließen ENDE -->

    <!-- Modal Ankauf abbrechen -->
    <BaseModal :visible="showModalAnkaufAbbrechen" @hide="hideModal">
      <div class="bg-white border border-gray-100 rounded-md shadow">
        <div
          class="flex justify-between px-6 py-2 border-b bg-gray-50 rounded-t-md"
        >
          <h5 class="py-2">Ankauf abbrechen</h5>
          <div class="flex items-center space-x-4">
            <p class="font-medium">E-Mail senden</p>
            <BaseToggle v-model="mailsent" />
          </div>
        </div>
        <div class="relative">
          <div
            v-show="!mailsent"
            class="absolute z-40 w-full h-full bg-white opacity-80"
          ></div>
          <div>
            <label class="block px-6 pt-2 text-sm font-medium">Betreff</label>
            <BaseInput class="w-full pl-6 border-none h-11" v-model="subject" />
          </div>
          <Editor ref="editor" :value="text" @input="saveMarkdown" />
        </div>
        <div class="px-6 py-2 bg-gray-50 rounded-b-md">
          <div class="flex justify-end py-2">
            <BaseButton
              @click.native="ankaufAbbrechen"
              :loading="loadingAbbrechen"
              variant="danger"
              >Ankauf abbrechen</BaseButton
            >
          </div>
        </div>
      </div>
    </BaseModal>
    <!-- Modal Ankauf abbrechen ENDE -->

    <!-- Modal Lagerbestand -->
    <BaseModal :visible="showModalLagerbestand" @hide="hideModal">
      <div class="bg-white border border-gray-100 rounded-md shadow">
        <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <SvgWarning class="w-6 h-6 text-brand-600" />
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg font-medium leading-6 text-gray-900"
                id="modal-headline"
              >
                Edelmetalle zum Lagerbestand hinzufügen?
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Sollen die analysierten Edelmetalle dem Lagerbestand
                  hinzugefügt werden?
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 overflow-auto -mx-7">
            <table
              class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
            >
              <thead>
                <tr class="text-left">
                  <BaseTh></BaseTh>
                  <BaseTh>Gegenstand</BaseTh>
                  <BaseTh>Edelmetall</BaseTh>
                  <BaseTh>Legierung</BaseTh>
                  <BaseTh>Menge</BaseTh>
                  <BaseTh>Einheit</BaseTh>
                  <BaseTh>Preis Gesamt</BaseTh>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(analyse, index) in ankauf.analysen"
                  :key="index"
                  @click="lagerbestandHinzufuegen(analyse)"
                  class="cursor-pointer"
                >
                  <BaseTd
                    ><input
                      :checked="lagerbestandPositionen.includes(analyse.id)"
                      type="checkbox"
                  /></BaseTd>
                  <BaseTd>{{ analyse.gegenstand }}</BaseTd>
                  <BaseTd
                    ><span v-if="analyse.edelmetall">{{
                      analyse.edelmetall | ucfirst
                    }}</span></BaseTd
                  >
                  <BaseTd>{{ analyse.legierung }}</BaseTd>
                  <BaseTd>{{ parseFloat(analyse.menge) }}</BaseTd>
                  <BaseTd>{{ analyse.einheit | ucfirst }}</BaseTd>
                  <BaseTd>{{ analyse.preis_gesamt | toCurrency }}</BaseTd>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
          <BaseButton
            :loading="loadingLagerbestand"
            @click.native="lagerbestand"
            class="w-32 w-full mb-3 sm:ml-3 sm:mb-0 sm:w-auto"
            >Ausgewählte Positionen hinzufügen</BaseButton
          >
          <BaseButton
            @click.native="showModalLagerbestand = false"
            variant="dark"
            :outline="true"
            class="w-full sm:ml-3 sm:w-auto"
            >Nein</BaseButton
          >
        </div>
      </div>
    </BaseModal>
    <!-- Modal Lagerbestand ENDE -->
  </div>
</template>

<script>
import Editor from "../../Editor.vue";
import EmailtextService from "../../../services/EmailtextService.js";
import AnkaufEreignisService from "../../../services/AnkaufEreignisService.js";
import AnkaufStatusService from "../../../services/AnkaufStatusService.js";
import LagerbestandService from "../../../services/LagerbestandService.js";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Editor
  },

  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  validations() {
    let validations = {
      formAbschliessen: {
        betrag: { required }
      }
    };

    if (this.formAbschliessen.medium == "ueberweisung") {
      validations.formAbschliessen.iban = {
        required
      };
    }

    if (this.formAbschliessen.medium == "paypal") {
      validations.formAbschliessen.paypal = {
        required
      };
    }

    return validations;
  },

  data() {
    return {
      showModalAngebot: false,
      showModalAnkaufAbschliessen: false,
      showModalAnkaufAbbrechen: false,
      loadingTextAngebot: false,
      loadingTextAbschliessen: false,
      loadingTextAbbrechen: false,
      loadingAngebot: false,
      loadingAbschliessen: false,
      loadingAbbrechen: false,
      text: "",
      subject: "Ihre Sendung wurde analysiert",
      markdown: null,
      mailsent: true,
      formAbschliessen: {
        betrag: null,
        medium: "ueberweisung",
        iban: null,
        paypal: null
      },
      showModalLagerbestand: false,
      loadingLagerbestand: false,
      lagerbestandPositionen: []
    };
  },

  computed: {
    auszahlungsbetrag() {
      return this.$options.filters.toCurrency(this.ankauf.angebotSummeAnalyse);
    },

    auszahlungsbetragNetto() {
      let auszahlungsbetrag = 0;
      this.ankauf.analysen.forEach(analyse => {
        auszahlungsbetrag += parseFloat(analyse.preis_gesamt);
      });
      return this.$options.filters.toCurrency(auszahlungsbetrag);
    }
  },

  watch: {
    auszahlungsbetrag: function(newVal) {
      this.formAbschliessen.betrag = newVal;
    }
  },

  created() {
    this.formAbschliessen.betrag = this.auszahlungsbetrag;

    if (
      this.ankauf.angebote.length &&
      this.ankauf.angebote[0].auszahlung_medium
    ) {
      this.formAbschliessen.medium = this.ankauf.angebote[0].auszahlung_medium
        ? this.ankauf.angebote[0].auszahlung_medium
        : "ueberweisung";

      if (this.formAbschliessen.medium == "ueberweisung") {
        this.formAbschliessen.iban = this.ankauf.angebote[0].medium_account;
      } else if (this.formAbschliessen.medium == "paypal") {
        this.formAbschliessen.paypal = this.ankauf.angebote[0].medium_account;
      }
    }

    Object.keys(this.ankauf.analysen).forEach(key => {
      if (this.ankauf.analysen[key].edelmetall) {
        this.lagerbestandPositionen.push(this.ankauf.analysen[key].id);
      }
    });
  },

  methods: {
    pause(milliseconds = 1000) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },

    roundUp(num, precision) {
      precision = Math.pow(10, precision);
      return Math.ceil(num * precision) / precision;
    },

    saveMarkdown(markdown) {
      this.markdown = markdown;
    },

    async hideModal() {
      this.showModalAngebot = false;
      this.showModalAnkaufAbschliessen = false;
      this.showModalAnkaufAbbrechen = false;
      this.showModalLagerbestand = false;
      await this.pause(300);
      this.text = "";
      this.mailsent = true;
    },

    async angebotSendenText() {
      this.loadingTextAngebot = true;
      const resp = await EmailtextService.getMailtext(
        this.ankauf.id,
        "angebot"
      );
      this.text = resp.data;
      this.showModalAngebot = true;
      this.loadingTextAngebot = false;
    },

    async ankaufAbbrechenText() {
      this.loadingTextAbbrechen = true;
      this.subject = "Der Rückversand wurde veranlasst";
      const resp = await EmailtextService.getMailtext(
        this.ankauf.id,
        "abgebrochen"
      );
      this.text = resp.data;
      this.showModalAnkaufAbbrechen = true;
      this.loadingTextAbbrechen = false;
    },

    async ankaufAbschliessenText() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.loadingTextAbschliessen = true;
        this.subject = "Ihr Geld ist auf dem Weg";
        const resp = await EmailtextService.getMailtext(
          this.ankauf.id,
          "abgeschlossen",
          {
            auszahlungsmedium: this.formAbschliessen.medium,
            auszahlungsbetrag: this.formAbschliessen.betrag
          }
        );
        this.text = resp.data;
        this.showModalAnkaufAbschliessen = true;
        this.loadingTextAbschliessen = false;
      }
    },

    async angebotSenden() {
      this.loadingAngebot = true;
      try {
        await AnkaufEreignisService.angebot({
          ankauf_id: this.ankauf.id,
          subject: this.subject,
          mailtext: this.markdown,
          mailsent: this.mailsent
        });
      } catch {
        this.loadingAngebot = false;
      }
      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loadingAngebot = false;
      this.showModalAngebot = false;
    },

    async ankaufAbschliessen() {
      this.loadingAbschliessen = true;
      let medium = null;
      let scheck_adresse_id = null;

      if (this.formAbschliessen.medium == "ueberweisung") {
        medium = this.formAbschliessen.iban;
      } else if (this.formAbschliessen.medium == "paypal") {
        medium = this.formAbschliessen.paypal;
      } else {
        scheck_adresse_id = this.ankauf.angebote[0].scheck_adresse
          ? this.ankauf.angebote[0].scheck_adresse.id
          : this.ankauf.adresse.id;
      }

      try {
        await AnkaufStatusService.abgeschlossen({
          ankauf_id: this.ankauf.id,
          subject: this.subject,
          mailtext: this.markdown,
          mailsent: this.mailsent,
          auszahlung: {
            angebot_id: this.ankauf.angebote[0].id,
            ausgezahlt: this.formAbschliessen.betrag,
            medium: this.formAbschliessen.medium,
            medium_account: medium,
            scheck_adresse_id: scheck_adresse_id
          }
        });
      } catch {
        this.loadingAbschliessen = false;
      }
      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loadingAbschliessen = false;
      this.showModalAnkaufAbschliessen = false;
      this.showModalLagerbestand = true;
    },

    async ankaufAbbrechen() {
      this.loadingAbbrechen = true;
      try {
        await AnkaufStatusService.abgebrochen({
          ankauf_id: this.ankauf.id,
          subject: this.subject,
          mailtext: this.markdown,
          mailsent: this.mailsent
        });
      } catch {
        this.loadingAbbrechen = false;
      }
      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loadingAbbrechen = false;
      this.showModalAnkaufAbbrechen = false;
    },

    lagerbestandHinzufuegen(position) {
      if (!this.lagerbestandPositionen.includes(position.id)) {
        this.lagerbestandPositionen.push(position.id);
      } else {
        let index = this.lagerbestandPositionen.indexOf(position.id);
        this.lagerbestandPositionen.splice(index, 1);
      }
    },

    async lagerbestand() {
      this.loadingLagerbestand = true;
      await LagerbestandService.store({
        ankauf_id: this.ankauf.id,
        positionen: this.lagerbestandPositionen
      });

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.showModalLagerbestand = false;
      this.loadingLagerbestand = false;
    }
  }
};
</script>

<style></style>
