<template>
  <div class="px-6 py-4 mb-6 border-t border-b bg-gray-50">
    <div class="grid gap-x-6 gap-y-4 md:grid-cols-3 ">
      <div>
        <label class="block mb-1 text-sm font-medium text-gray-600"
          >Gegenstand</label
        >
        <BaseInput
          v-model="analyse.gegenstand"
          :readonly="analyse.gegenstand == 'Versandkosten'"
          maxlength="50"
          type="text"
          class="w-full h-11"
        />
      </div>
      <div v-show="analyse.gegenstand != 'Versandkosten'">
        <label class="block mb-1 text-sm font-medium text-gray-600"
          >Edelmetall</label
        >
        <BaseSelect v-model="analyse.edelmetall" class="w-full h-11">
          <option value="gold">Gold</option>
          <option value="silber">Silber</option>
          <option value="platin">Platin</option>
          <option value="palladium">Palladium</option>
          <option value="">-</option>
        </BaseSelect>
      </div>
      <div v-show="analyse.gegenstand != 'Versandkosten'">
        <label class="block mb-1 text-sm font-medium text-gray-600"
          >Legierung in Tsd.</label
        >
        <BaseInput
          :disabled="analyse.edelmetall === null"
          v-model="analyse.legierung"
          type="number"
          class="w-full h-11"
        />
      </div>
      <div>
        <label class="block mb-1 text-sm font-medium text-gray-600"
          >Menge</label
        >
        <BaseInput v-model="analyse.menge" class="w-full h-11" />
      </div>
      <div v-show="analyse.gegenstand != 'Versandkosten'">
        <label class="block mb-1 text-sm font-medium text-gray-600"
          >Einheit</label
        >
        <BaseSelect v-model="analyse.einheit" class="w-full h-11">
          <option value="gramm">Gramm</option>
          <option value="stück">Stück</option>
        </BaseSelect>
      </div>
      <div>
        <label class="block mb-1 text-sm font-medium text-gray-600"
          >Preis pro Einheit</label
        >
        <BaseInput v-model="analyse.preis" type="number" class="w-full h-11" />
      </div>
    </div>
    <div
      class="flex flex-col mt-4 space-y-3 md:space-y-0 md:flex-row md:space-x-4"
      :class="{
        'justify-center': analyse.gegenstand == 'Versandkosten',
        'justify-between': analyse.gegenstand != 'Versandkosten'
      }"
    >
      <div v-if="analyse.gegenstand != 'Versandkosten'" class="flex space-x-2">
        <p class="text-sm">Handelsfähig</p>
        <BaseToggle v-model="analyse.handelsfaehig" :small="true" />
      </div>
      <p class="text-sm text-gray-600">
        Auszahlungsbetrag:
        {{ analyse.preis_gesamt | toCurrency }}
      </p>
      <p
        @click="$emit('entfernen')"
        class="text-sm text-red-600 cursor-pointer"
        v-if="analyse.gegenstand != 'Versandkosten'"
      >
        Position entfernen
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    analyse: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState({
      grammMarge: state => state.boersenkurse.grammMarge
    })
  },

  watch: {
    "analyse.handelsfaehig": function() {
      this.preisGramm();
    },

    "analyse.edelmetall": function() {
      this.preisGramm();
    },

    "analyse.legierung": function() {
      this.preisGramm();
    },

    "analyse.menge": function() {
      this.berechnen();
    },

    "analyse.preis": function() {
      this.berechnen();
    }
  },

  methods: {
    preisGramm() {
      if (this.analyse.edelmetall) {
        let handelsfaehig = "";
        if (this.analyse.handelsfaehig) {
          handelsfaehig = "_handelsfaehig";
        }

        this.analyse.preis = (
          (this.analyse.legierung *
            this.grammMarge[this.analyse.edelmetall + handelsfaehig]) /
          1000
        ).toFixed(2);
      }
    },

    berechnen() {
      this.analyse.menge = this.analyse.menge.replace(",", ".");
      this.analyse.preis_gesamt = (
        this.analyse.menge * this.analyse.preis
      ).toFixed(2);
      this.$emit("berechnen");
    }
  }
};
</script>

<style></style>
