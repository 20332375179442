import apiClient from "../../services/ApiClient.js";

export const namespaced = true;

export const state = {
  authenticated: false,
  user: null
};

export const getters = {
  authenticated(state) {
    return state.authenticated;
  },

  user(state) {
    return state.user;
  }
};

export const mutations = {
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },

  SET_USER(state, value) {
    state.user = value;
  }
};

export const actions = {
  async signIn({ dispatch }, credentials) {
    await apiClient({
      url: "/sanctum/csrf-cookie",
      baseURL: process.env.VUE_APP_API_SERVER
    });
    try {
      await apiClient({
        method: "POST",
        url: "/mitarbeiter/login",
        baseURL: process.env.VUE_APP_API_SERVER,
        params: credentials
      });
    } catch (err) {
      throw err.response.data.errors.email[0];
    }

    return dispatch("me");
  },

  async logout({ dispatch }) {
    await apiClient({
      method: "POST",
      url: "/mitarbeiter/logout",
      baseURL: process.env.VUE_APP_API_SERVER
    });

    return dispatch("me");
  },

  me({ commit }) {
    return apiClient
      .get("/")
      .then(response => {
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", response.data);
      })
      .catch(() => {
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", null);
      });
  }
};
