import Vue from "vue";
import Vuex from "vuex";

import * as menu from "./modules/menu.js";
import * as auth from "./modules/auth.js";
import * as notification from "./modules/notification.js";
import * as ankauf from "./modules/ankauf.js";
import * as stats from "./modules/stats.js";
import * as boersenkurse from "./modules/boersenkurse.js";
import * as marge from "./modules/marge.js";
import * as berechnung from "./modules/berechnung.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    menu,
    auth,
    notification,
    ankauf,
    stats,
    boersenkurse,
    marge,
    berechnung
  }
});
