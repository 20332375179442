<template>
  <TransitionFade>
    <div
      v-show="visible"
      :style="{ 'transition-delay: 300ms': visible }"
      class="fixed inset-0 z-10 overflow-y-auto"
    >
      <div
        class="flex items-end justify-center min-h-screen px-4 py-4 text-center sm:block sm:p-0"
      >
        <TransitionFade>
          <div
            v-show="visible"
            @click="$emit('hide')"
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
          >
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </TransitionFade>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <TransitionFadeScale>
          <div
            v-show="visible"
            class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:w-11/12 sm:my-8 sm:align-middle md:w-4/5 2xl:w-3/5"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <slot></slot>
          </div>
        </TransitionFadeScale>
      </div>
    </div>
  </TransitionFade>
</template>

<script>
import TransitionFadeScale from "../Transitions/TransitionFadeScale";
import TransitionFade from "../Transitions/TransitionFade";

export default {
  components: {
    TransitionFadeScale,
    TransitionFade
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  watch: {
    visible(newVal) {
      if (newVal) {
        const body = document.querySelector("body");
        body.classList = "overflow-hidden";
      } else {
        const body = document.querySelector("body");
        body.classList = "";
      }
    }
  }
};
</script>

<style></style>
