import BerechnungService from "../../services/BerechnungService.js";

export const namespaced = true;

export const state = {
  berechnungen: {},
  berechnung: {}
};

export const mutations = {
  SET_BERECHNUNGEN(state, berechnungen) {
    state.berechnungen = berechnungen;
  },

  SET_BERECHNUNG(state, berechnung) {
    state.berechnung = berechnung;
  }
};

export const actions = {
  async index({ commit }) {
    const resp = await BerechnungService.index();
    commit("SET_BERECHNUNGEN", resp.data);
  },

  async show({ commit }, id) {
    const resp = await BerechnungService.show(id);
    commit("SET_BERECHNUNG", resp.data);
  }
};
