<template>
  <div>
    <BaseDashboardContainer>
      <h4>Paketeingang bestätigen</h4>
      <p class="text-gray-600">
        Prüfe ob die Kundenreferenz auf dem Retourenlabel identisch mit der
        Ankaufs ID ist, bevor du den Eingang bestätigtst.
      </p>
      <BaseButton
        @click.native="paketeingangText"
        :loading="loadingText"
        class="mt-4"
        ><template v-slot:loading><SvgBox class="w-5 h-5 mr-2"/></template
        >Paketeingang bestätigen</BaseButton
      >
    </BaseDashboardContainer>

    <!-- Modal Paketeingang -->
    <BaseModal :visible="showModal" @hide="hideModal">
      <div class="bg-white border border-gray-100 rounded-md shadow">
        <div
          class="flex justify-between px-6 py-2 border-b bg-gray-50 rounded-t-md"
        >
          <h5 class="py-2">Paketeingang bestätigen</h5>
          <div class="flex items-center space-x-4">
            <p class="font-medium">E-Mail senden</p>
            <BaseToggle v-model="mailsent" />
          </div>
        </div>
        <div class="relative">
          <div
            v-show="!mailsent"
            class="absolute z-40 w-full h-full bg-white opacity-80"
          ></div>
          <div>
            <label class="block px-6 pt-2 text-sm font-medium">Betreff</label>
            <BaseInput class="w-full pl-6 border-none h-11" v-model="subject" />
          </div>
          <Editor ref="editor" :value="text" @input="saveMarkdown" />
        </div>
        <div class="px-6 py-2 bg-gray-50 rounded-b-md">
          <div class="flex justify-end py-2">
            <BaseButton
              @click.native="paketeingangBestaetigen"
              :loading="loadingBestaetigen"
              >Paketeingang bestätigen</BaseButton
            >
          </div>
        </div>
      </div>
    </BaseModal>
    <!-- Modal Paketeingang ENDE -->
  </div>
</template>

<script>
import Editor from "../../Editor.vue";
import EmailtextService from "../../../services/EmailtextService.js";
import AnkaufEreignisService from "../../../services/AnkaufEreignisService.js";

export default {
  components: {
    Editor
  },

  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showModal: false,
      loadingText: false,
      loadingBestaetigen: false,
      text: "",
      subject: "Ihre Sendung ist bei uns eingegangen",
      markdown: null,
      mailsent: true
    };
  },

  methods: {
    pause(milliseconds = 1000) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },

    async paketeingangText() {
      this.loadingText = true;
      const resp = await EmailtextService.getMailtext(
        this.ankauf.id,
        "eingang"
      );
      this.text = resp.data;
      this.showModal = true;
      this.loadingText = false;
    },

    saveMarkdown(markdown) {
      this.markdown = markdown;
    },

    async hideModal() {
      this.showModal = false;
      await this.pause(300);
      this.text = "";
    },

    async paketeingangBestaetigen() {
      this.loadingBestaetigen = true;
      try {
        await AnkaufEreignisService.eingang({
          ankauf_id: this.ankauf.id,
          subject: this.subject,
          mailtext: this.markdown,
          mailsent: this.mailsent
        });
      } catch {
        this.loadingBestaetigen = false;
      }

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.showModal = false;
      this.loadingBestaetigen = false;
    }
  }
};
</script>
