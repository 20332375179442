<template>
  <span :is="type" v-bind="attributes">
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    group: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      attributes: {
        "enter-active-class": "duration-300 ease-out",
        "enter-class": "translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95",
        "enter-to-class": "translate-y-0 opacity-100 sm:scale-100",
        "leave-active-class": "duration-200 ease-in",
        "leave-class": "translate-y-0 opacity-100 sm:scale-100",
        "leave-to-class": "translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      }
    };
  },
  computed: {
    type() {
      if (this.group) {
        return "transition-group";
      }
      return "transition";
    }
  }
};
</script>
