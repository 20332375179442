import Vue from "vue";

Vue.filter("toCurrency", function(value) {
  if (value) {
    if (typeof value !== "number") {
      value = parseFloat(value);
    }
    var formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2
    });
    return formatter.format(value);
  }
});

Vue.filter("ucfirst", function(value) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
});

Vue.filter("toGramm", function(value) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formatter.format(value) + " Gramm";
});

Vue.filter("prettyBytes", function(num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? "-" : "") + num + " B";
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  );
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? "-" : "") + num + " " + unit;
});
