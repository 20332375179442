import AnkaufService from "../../services/AnkaufService.js";

export const namespaced = true;

export const state = {
  ankaufCount: null
};

export const getters = {
  ankaufCount(state) {
    return state.ankaufCount;
  }
};

export const mutations = {
  SET_ANKAUF_COUNT(state, ankaufCount) {
    state.ankaufCount = ankaufCount;
  }
};

export const actions = {
  async ankaufCount({ commit, getters }) {
    if (!getters.ankaufCount) {
      const resp = await AnkaufService.ankaufCount();
      commit("SET_ANKAUF_COUNT", resp.data);
    }
  }
};
