<template>
  <div class="flex items-center justify-between" @click="toggle = !toggle">
    <div
      class="flex items-center p-1 duration-300 ease-in-out bg-gray-300 rounded-full"
      :class="{ 'bg-blue-500': toggle, 'w-12 h-6': !small, 'w-9 h-5': small }"
    >
      <div
        class="duration-300 ease-in-out transform bg-white rounded-full shadow-md"
        :class="{
          'translate-x-4': toggle && small,
          'translate-x-6': toggle && !small,
          'w-4 h-4': !small,
          'w-3 h-3': small
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number],
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      toggle: this.value
    };
  },

  watch: {
    value(newVal) {
      this.toggle = newVal;
    },

    toggle() {
      this.$emit("input", this.toggle);
    }
  }
};
</script>
