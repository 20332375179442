<template>
  <div class="pb-10">
    <div class="border-b">
      <BaseContainer>
        <div class="flex flex-wrap justify-between">
          <div class="flex flex-col justify-center">
            <p class="font-medium">Einstellungen</p>
          </div>
        </div>
      </BaseContainer>
    </div>
    <BaseContainer>
      <div class="grid grid-cols-1 gap-6 2xl:grid-cols-9">
        <div class="2xl:col-span-6">
          <BaseDashboardContainer>
            <template v-slot:header>
              <h5>Aktuelle Margen</h5>
            </template>
            <div class="-m-6 overflow-x-auto">
              <table
                class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
              >
                <tr>
                  <BaseTh></BaseTh>
                  <BaseTh class="text-left">Gold</BaseTh>
                  <BaseTh class="text-left">Silber</BaseTh>
                  <BaseTh class="text-left">Platin</BaseTh>
                  <BaseTh class="text-left">Palladium</BaseTh>
                </tr>
                <tr>
                  <BaseTh class="text-left">Handelsfähig</BaseTh>
                  <BaseTd>{{ marge.gold_handelsfaehig }} %</BaseTd>
                  <BaseTd>{{ marge.silber_handelsfaehig }} %</BaseTd>
                  <BaseTd>{{ marge.platin_handelsfaehig }} %</BaseTd>
                  <BaseTd>{{ marge.palladium_handelsfaehig }} %</BaseTd>
                </tr>
                <tr>
                  <BaseTh class="text-left">Nicht Handelsfähig</BaseTh>
                  <BaseTd>{{ marge.gold }} %</BaseTd>
                  <BaseTd>{{ marge.silber }} %</BaseTd>
                  <BaseTd>{{ marge.platin }} %</BaseTd>
                  <BaseTd>{{ marge.palladium }} %</BaseTd>
                </tr>
              </table>
            </div>
            <template v-slot:footer>
              <BaseButton
                @click.native="showModalMargeBearbeiten = true"
                class="ml-auto"
                variant="white"
                :small="true"
                >Margen bearbeiten</BaseButton
              >
            </template>
          </BaseDashboardContainer>
        </div>
        <div class="2xl:col-span-3">
          <BaseDashboardContainer>
            <template v-slot:header>
              <h5>Verlauf</h5>
            </template>
            <div class="flex flex-col -mx-4 -my-4 overflow-y-auto max-h-96">
              <div
                v-for="(marge, index) in margen"
                :key="index"
                class="p-4 border-b"
              >
                <p>Erstellt von: {{ marge.mitarbeiter.vorname }}</p>
                <p>Erstellt am: {{ marge.datum }} Uhr</p>
                <p
                  @click="openMarge(marge)"
                  class="font-medium cursor-pointer text-brand-400"
                >
                  anzeigen
                </p>
              </div>
            </div>
          </BaseDashboardContainer>
        </div>
      </div>
    </BaseContainer>

    <!-- Modal Marge -->
    <BaseModal :visible="showModalMarge" @hide="showModalMarge = false">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h5>Marge</h5>
          <p class="text-gray-600">aktiv seit {{ marge.datum }} Uhr</p>
        </template>
        <div class="-m-6 overflow-x-auto">
          <table
            class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
          >
            <tr>
              <BaseTh></BaseTh>
              <BaseTh class="text-left">Gold</BaseTh>
              <BaseTh class="text-left">Silber</BaseTh>
              <BaseTh class="text-left">Platin</BaseTh>
              <BaseTh class="text-left">Palladium</BaseTh>
            </tr>
            <tr>
              <BaseTh class="text-left">Handelsfähig</BaseTh>
              <BaseTd>{{ margeSelected.gold_handelsfaehig }} %</BaseTd>
              <BaseTd>{{ margeSelected.silber_handelsfaehig }} %</BaseTd>
              <BaseTd>{{ margeSelected.platin_handelsfaehig }} %</BaseTd>
              <BaseTd>{{ margeSelected.palladium_handelsfaehig }} %</BaseTd>
            </tr>
            <tr>
              <BaseTh class="text-left">Nicht Handelsfähig</BaseTh>
              <BaseTd>{{ margeSelected.gold }} %</BaseTd>
              <BaseTd>{{ margeSelected.silber }} %</BaseTd>
              <BaseTd>{{ margeSelected.platin }} %</BaseTd>
              <BaseTd>{{ margeSelected.palladium }} %</BaseTd>
            </tr>
          </table>
        </div>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Marge ENDE -->

    <!-- Modal Marge bearbeiten -->
    <BaseModal
      :visible="showModalMargeBearbeiten"
      @hide="showModalMargeBearbeiten = false"
    >
      <BaseDashboardContainer>
        <template v-slot:header>
          <h5>Marge bearbeiten</h5>
        </template>
        <div class="-m-6 overflow-x-auto">
          <table
            class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
          >
            <tr>
              <BaseTh></BaseTh>
              <BaseTh class="text-left">Gold</BaseTh>
              <BaseTh class="text-left">Silber</BaseTh>
              <BaseTh class="text-left">Platin</BaseTh>
              <BaseTh class="text-left">Palladium</BaseTh>
            </tr>
            <tr>
              <BaseTh class="text-left">Handelsfähig</BaseTh>
              <BaseTd
                ><BaseInput v-model="margeBearbeiten.gold_handelsfaehig"
              /></BaseTd>
              <BaseTd
                ><BaseInput v-model="margeBearbeiten.silber_handelsfaehig"
              /></BaseTd>
              <BaseTd
                ><BaseInput v-model="margeBearbeiten.platin_handelsfaehig"
              /></BaseTd>
              <BaseTd
                ><BaseInput v-model="margeBearbeiten.palladium_handelsfaehig"
              /></BaseTd>
            </tr>
            <tr>
              <BaseTh class="text-left">Nicht Handelsfähig</BaseTh>
              <BaseTd><BaseInput v-model="margeBearbeiten.gold"/></BaseTd>
              <BaseTd><BaseInput v-model="margeBearbeiten.silber"/></BaseTd>
              <BaseTd><BaseInput v-model="margeBearbeiten.platin"/></BaseTd>
              <BaseTd><BaseInput v-model="margeBearbeiten.palladium"/></BaseTd>
            </tr>
          </table>
        </div>
        <template v-slot:footer>
          <div
            class="flex flex-col justify-end space-x-0 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
          >
            <BaseButton
              @click.native="resetMarge"
              :outline="true"
              variant="dark"
              >abbrechen</BaseButton
            >
            <BaseButton
              @click.native="margeSpeichern"
              :loading="loading"
              variant="danger"
              >Änderungen speichern</BaseButton
            >
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Marge bearbeiten ENDE -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MargeService from "../services/MargeService";

export default {
  data() {
    return {
      margeSelected: {},
      showModalMarge: false,
      showModalMargeBearbeiten: false,
      margeBearbeiten: {
        gold: null,
        gold_handelsfaehig: null,
        silber: null,
        silber_handelsfaehig: null,
        platin: null,
        platin_handelsfaehig: null,
        palladium: null,
        palladium_handelsfaehig: null
      },
      loading: false
    };
  },

  computed: {
    ...mapState({
      marge: state => state.marge.marge,
      margen: state => state.marge.margen
    })
  },

  created() {
    this.margeBearbeiten = { ...this.marge };
  },

  methods: {
    openMarge(marge) {
      this.margeSelected = marge;
      this.showModalMarge = true;
    },

    resetMarge() {
      this.showModalMargeBearbeiten = false;
      this.margeBearbeiten = { ...this.marge };
    },

    async margeSpeichern() {
      this.loading = true;
      await MargeService.store(this.margeBearbeiten);
      this.$store.dispatch("marge/show");
      this.$store.dispatch("marge/index");
      this.loading = false;
      this.showModalMargeBearbeiten = false;
    }
  }
};
</script>

<style></style>
