import apiClient from "./ApiClient.js";

export default {
  auszahlung(data) {
    return apiClient.post("/auszahlung", data);
  },

  loeschen(data) {
    return apiClient.post("/auszahlung/loeschen", data);
  }
};
