<template>
  <div>
    <BaseDashboardContainer v-show="bearbeiten || !ankauf.analyse">
      <template v-slot:header>
        <h4>Analyse {{ ankauf.analyse ? "bearbeiten" : "erstellen" }}</h4>
      </template>
      <div>
        <BaseButton @click.native="positionHinzufuegen" variant="white"
          ><SvgPlus class="w-5 h-5 mr-1" />Position hinzufügen</BaseButton
        >
        <div class="mt-8 -mx-6">
          <AnkaufAnalysePosition
            v-for="(analyse, index) in analysen"
            :key="index"
            :analyse="analyse"
            @entfernen="positionEntfernen(index)"
            @berechnen="auszahlungsbetragBerechnen"
          />
          <div class="px-6">
            <h5>
              Auszahlungsbetrag gesamt:
              <span class="text-green-600">{{ auszahlungsbetrag }}</span>
            </h5>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div
          class="flex flex-col justify-end space-x-0 md:space-x-4 md:flex-row"
        >
          <BaseButton
            v-show="ankauf.analyse"
            @click.native="bearbeitenAbbrechen"
            variant="danger"
            :outline="true"
            >abbrechen</BaseButton
          >
          <BaseButton
            @click.native="analyseSpeichern"
            :loading="loadingAnalyse"
            class="order-first mb-4 md:order-last md:mb-0"
            ><template v-slot:loading
              ><SvgVial class="inline w-5 h-5 mr-1"
            /></template>
            Analyse speichern</BaseButton
          >
        </div>
      </template>
    </BaseDashboardContainer>

    <BaseDashboardContainer v-if="ankauf.analyse && !bearbeiten">
      <template v-slot:header>
        <h4>Analyse</h4>
      </template>
      <div class="-m-6 overflow-x-scroll">
        <table
          class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
        >
          <thead>
            <tr class="text-left">
              <BaseTh>Gegenstand</BaseTh>
              <BaseTh>Edelmetall</BaseTh>
              <BaseTh>Legierung</BaseTh>
              <BaseTh>Menge</BaseTh>
              <BaseTh>Einheit</BaseTh>
              <BaseTh>Preis / Einheit</BaseTh>
              <BaseTh>Preis Gesamt</BaseTh>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(analyse, index) in ankauf.analysen" :key="index">
              <BaseTd>{{ analyse.gegenstand }}</BaseTd>
              <BaseTd
                ><span v-if="analyse.edelmetall">{{
                  analyse.edelmetall | ucfirst
                }}</span></BaseTd
              >
              <BaseTd>{{ analyse.legierung }}</BaseTd>
              <BaseTd>{{ parseFloat(analyse.menge) }}</BaseTd>
              <BaseTd>{{ analyse.einheit | ucfirst }}</BaseTd>
              <BaseTd>{{ analyse.preis | toCurrency }}</BaseTd>
              <BaseTd>{{ analyse.preis_gesamt | toCurrency }}</BaseTd>
            </tr>
          </tbody>
        </table>
        <p class="px-6 py-3 font-medium">Summe: {{ auszahlungsbetrag }}</p>
      </div>
      <template v-if="!ankauf.lagerbestandAktualisiert" v-slot:footer>
        <div class="flex justify-end">
          <BaseButton
            @click.native="bearbeiten = true"
            :small="true"
            variant="white"
            ><SvgEdit class="w-4 h-4 mr-1.5" />Bearbeiten</BaseButton
          >
        </div>
      </template>
    </BaseDashboardContainer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AnalyseService from "../../../services/AnalyseService.js";
import AnkaufAnalysePosition from "./AnkaufAnalysePosition.vue";

export default {
  components: {
    AnkaufAnalysePosition
  },

  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      bearbeiten: false,
      loadingAnalyse: false,
      auszahlungsbetrag: 0,
      analyseDefault: {
        id: null,
        ankauf_id: this.ankauf.id,
        handelsfaehig: false,
        gegenstand: "",
        edelmetall: "gold",
        legierung: null,
        menge: "",
        einheit: "gramm",
        preis: "",
        preis_gesamt: 0
      },
      versandPosition: {
        handelsfaehig: false,
        gegenstand: "Versandkosten",
        edelmetall: null,
        legierung: null,
        menge: "1",
        einheit: "stück",
        preis: this.ankauf.versandart == "werttransport" ? -25 : -5.99,
        preis_gesamt: this.ankauf.versandart == "werttransport" ? -25 : -5.99
      },
      analysen: []
    };
  },

  computed: {
    ...mapState({
      grammMarge: state => state.boersenkurse.grammMarge
    })
  },

  created() {
    this.$store.dispatch("boersenkurse/grammMarge");
    if (!this.ankauf.analyse) {
      this.analysen.push({ ...this.versandPosition });
      this.positionHinzufuegen();
    } else {
      this.analysen = this.ankauf.analysen;
      this.auszahlungsbetragBerechnen();
    }
  },

  methods: {
    positionHinzufuegen() {
      this.analysen.push({ ...this.analyseDefault });
    },

    positionEntfernen(index) {
      this.analysen.splice(index, 1);
      this.auszahlungsbetragBerechnen();
    },

    auszahlungsbetragBerechnen() {
      this.auszahlungsbetrag = 0;
      this.analysen.forEach(analyse => {
        this.auszahlungsbetrag += parseFloat(analyse.preis_gesamt);
      });
      this.auszahlungsbetrag = this.$options.filters.toCurrency(
        this.auszahlungsbetrag
      );
    },

    async bearbeitenAbbrechen() {
      this.bearbeiten = false;
      await this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.analysen = this.ankauf.analysen;
    },

    async analyseSpeichern() {
      this.loadingAnalyse = true;
      const update = this.ankauf.analyse ? true : false;
      try {
        await AnalyseService.store(update, {
          ankauf_id: this.ankauf.id,
          analysen: this.analysen,
          auszahlungsbetrag: this.auszahlungsbetrag
        });
      } catch {
        this.loadingAnalyse = false;
      }

      //Die Analysen müssen geupdatet werden damit auch neu erstelle Positionen eine ID haben und bei erneutem speichern nicht eine neue Position erstellt wird.
      await this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.analysen = this.ankauf.analysen;

      this.loadingAnalyse = false;
      this.bearbeiten = false;
    }
  }
};
</script>
