<template>
  <div>
    <div class="relative overflow-x-auto overflow-y-auto">
      <table
        class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
      >
        <thead class="whitespace-nowrap">
          <tr class="text-left">
            <BaseTh><SvgCalendar class="inline w-5 h-5 mr-1" />Datum</BaseTh>
            <BaseTh
              ><SvgListNumbered class="inline w-5 h-5 mr-1" />Positionen</BaseTh
            >
            <BaseTh><SvgDownload class="w-5 h-5 mr-1" />Download</BaseTh>
            <BaseTh
              ><SvgEuro class="inline w-5 h-5 mr-1" />Auszahlungsbetrag</BaseTh
            >
          </tr>
        </thead>
        <tbody v-if="berechnungen">
          <tr
            v-for="(berechnung, index) in berechnungen.data"
            :key="index"
            @click="berechnungOeffnen(berechnung)"
            class="transition-all cursor-pointer whitespace-nowrap hover:bg-white"
          >
            <BaseTd>{{ berechnung.datum }} Uhr</BaseTd>
            <BaseTd>{{ berechnung.berechnungen.length }} Stück</BaseTd>
            <BaseTd
              ><SvgClose
                v-if="!berechnung.download"
                class="-ml-1 text-red-600 w-7 h-7"/><SvgCheck
                v-else
                class="-ml-1 text-green-600 w-7 h-7"
            /></BaseTd>
            <BaseTd class="text-sm">{{
              berechnung.ergebnis.auszahlungsbetrag | toCurrency
            }}</BaseTd>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-6">
      <p class="mb-2 text-sm text-center text-gray-600">
        {{ verbleibend }} Berechnungen verbleibend
      </p>
      <BaseButton
        v-show="verbleibend > 0"
        :loading="loadingMore"
        @click.native="weiter"
        variant="dark"
        :outline="true"
        class="mx-auto"
        >mehr anzeigen</BaseButton
      >
    </div>
    <!-- Modal Berechnung -->
    <BaseModal :visible="visible" @hide="visible = false">
      <BaseDashboardContainer v-if="berechnung">
        <template v-slot:header>
          <h4>Berechnung</h4>
          <p class="text-gray-600">Rechner ID: {{ berechnung.rechner_id }}</p>
        </template>
        <div>
          <div class="-mx-6 -mt-10 overflow-x-auto">
            <table
              class="relative w-full my-4 whitespace-no-wrap border-b border-collapse table-auto table-striped"
            >
              <thead>
                <tr class="text-left">
                  <BaseTh>Gegenstand</BaseTh>
                  <BaseTh>Gewicht</BaseTh>
                  <BaseTh>Edelmetall</BaseTh>
                  <BaseTh>Legierung</BaseTh>
                  <BaseTh>Börsenkurs</BaseTh>
                  <BaseTh>Marge</BaseTh>
                  <BaseTh>Auszahlungsbetrag</BaseTh>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(gegenstand, index) in berechnung.ergebnis.gegenstände"
                  :key="index"
                >
                  <BaseTd>{{ gegenstand.gegenstand }}</BaseTd>
                  <BaseTd>{{ gegenstand.gewicht }}g</BaseTd>
                  <BaseTd>{{ gegenstand.edelmetall | ucfirst }}</BaseTd>
                  <BaseTd>{{ gegenstand.legierung }}</BaseTd>
                  <BaseTd>{{ gegenstand.boersenkurs | toCurrency }}</BaseTd>
                  <BaseTd>{{ gegenstand.marge | toCurrency }}</BaseTd>
                  <BaseTd>{{
                    gegenstand.auszahlungsbetrag | toCurrency
                  }}</BaseTd>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <p class="text-sm text-gray-600">
              Börsenwert: {{ berechnung.ergebnis.boersenkurs | toCurrency }}
            </p>
            <p class="text-sm text-gray-600">
              Handelsmarge: {{ berechnung.ergebnis.marge | toCurrency }}
            </p>
            <p class="text-sm text-gray-600">
              Versandkosten:
              {{ berechnung.ergebnis.versandkosten | toCurrency }}
            </p>
            <p class="relative mt-2 text-lg font-semibold">
              Auszahlungsbetrag:
              {{ berechnung.ergebnis.auszahlungsbetrag | toCurrency }}
            </p>
          </div>
        </div>
        <template v-slot:footer>
          <router-link :to="'/berechnung/' + berechnung.id"
            ><BaseButton class="ml-auto" variant="primary"
              >Berechnung öffnen</BaseButton
            ></router-link
          >
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- END Modal Berechnung -->
  </div>
</template>

<script>
import BerechnungService from "../services/BerechnungService";

export default {
  data() {
    return {
      visible: false,
      berechnungen: this.$store.state.berechnung.berechnungen,
      filter: {
        page: 1,
        rechner_id: null,
        dateAfter: null,
        dateBefore: null
      },
      filterDefault: { ...this.filter },
      loadingMore: false,
      berechnung: null
    };
  },

  computed: {
    verbleibend() {
      if (this.berechnungen) {
        return this.berechnungen.total - this.berechnungen.data.length;
      }
      return null;
    }
  },

  methods: {
    berechnungOeffnen(berechnung) {
      this.berechnung = berechnung;
      this.visible = true;
    },

    async weiter() {
      if (this.filter.page < this.berechnungen.last_page) {
        this.loadingMore = true;
        this.filter.page++;
        const resp = await this.fetch();
        this.berechnungen.data.push(...resp.data);
        this.loadingMore = false;
      }
    },

    async fetch() {
      const resp = await BerechnungService.index(this.filter);
      return resp.data;
    }
  }
};
</script>

<style></style>
