<template>
  <div class="relative inline-block">
    <span v-if="ankauf.paketeingang === true" class="flex w-3 h-3">
      <span
        class="relative inline-flex w-3 h-3 bg-green-500 rounded-full"
      ></span>
    </span>
    <span v-else-if="ankauf.versandErledigt === false" class="flex w-3 h-3">
      <span
        class="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"
      ></span>
      <span class="relative inline-flex w-3 h-3 bg-red-500 rounded-full"></span>
    </span>
    <div v-else-if="ankauf.versandErledigt === true">
      <span
        class="relative inline-flex w-3 h-3 bg-yellow-500 rounded-full"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ankauf: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
