<template>
  <div>
    <BaseDashboardContainer class="mt-10">
      <template v-slot:header>
        <h4>E-Mails</h4>
      </template>
      <div
        v-for="(email, index) in ankauf.notifications"
        :key="index"
        class="flex flex-col"
      >
        <div :class="{ 'pt-3 mt-3 border-t': index != 0 }">
          <p class="text-sm text-gray-600">
            {{ email.datum }} Uhr -
            <span v-if="email.mitarbeiter" class="text-brand-600"
              ><SvgUser class="w-4 h-4" />{{ email.mitarbeiter.vorname }}</span
            >
            <span v-else>System</span>
          </p>
          <p @click="openMail(email)" class="font-medium cursor-pointer">
            {{ email.betreff }}
          </p>
        </div>
      </div>
    </BaseDashboardContainer>

    <!-- Modal E-Mail -->
    <BaseModal :visible="showModal" @hide="showModal = false">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>E-Mail</h4>
        </template>
        <div v-if="mail">
          <p class="text-gray-600">Gesendet von:</p>
          <p class="mb-6">
            <span v-if="mail.mitarbeiter">{{ mail.mitarbeiter.vorname }}</span>
            <span v-else>System</span>
          </p>
          <p class="text-gray-600">Betreff:</p>
          <p class="mb-6">{{ mail.betreff }}</p>
          <p class="text-gray-600">Nachricht:</p>
          <p>{{ mail.nachricht }}</p>
        </div>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal E-Mail ENDE -->
  </div>
</template>

<script>
export default {
  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showModal: false,
      mail: null
    };
  },

  methods: {
    openMail(mail) {
      this.showModal = true;
      this.mail = mail;
    }
  }
};
</script>

<style></style>
