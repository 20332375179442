<template>
  <div
    :class="{
      'text-blue-700 bg-blue-200': status == 'aktiv',
      'text-green-700 bg-green-200': status == 'abgeschlossen',
      'text-red-700 bg-red-200': status == 'abgebrochen',
      'text-white bg-gray-900': status == 'archiviert'
    }"
    class="inline-block px-4 py-0 text-sm rounded-xl"
  >
    {{ status }}
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  }
};
</script>

<style></style>
