<template>
  <div
    class="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          404
        </h2>
        <p class="mt-3 font-medium text-center text-brand-600">
          <router-link to="/">zur Startseite</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      remember: false,
      error: null
    };
  },

  methods: {
    submit() {
      this.loading = true;
      this.$store
        .dispatch("auth/signIn", {
          email: this.email,
          password: this.password,
          remember: this.remember
        })
        .then(() => {
          this.$router.replace({ name: "Home" });
        })
        .catch(err => {
          this.loading = false;
          this.error = err;
        });
      this.loading = false;
    }
  }
};
</script>

<style></style>
