<template>
  <button
    :disabled="loading"
    :class="
      classes[variant].base +
        classes[variant][outlineClass] +
        classes.size +
        ' relative flex justify-center px-4 py-2 font-medium border transition-all rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50'
    "
    type="submit"
  >
    <div>
      <SvgSpinner class="relative -top-px" v-if="loading" />
      <slot v-if="!loading" name="loading"></slot>
    </div>
    <div class="mx-auto"><slot></slot></div>
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      default: "primary",
      type: String,
      validator: value => {
        return [
          "primary",
          "secondary",
          "success",
          "warning",
          "danger",
          "dark",
          "white"
        ].includes(value);
      }
    },
    outline: {
      default: false,
      type: Boolean
    },
    small: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      classes: {
        size: this.small ? " text-xs" : " text-sm",
        primary: {
          base:
            "hover:bg-brand-600 focus:ring-brand-600 focus:ring-opacity-50 focus:ring-offset-brand-100",
          outline: " border-brand-600 text-brand-600 hover:text-white",
          noOutline: " bg-brand-500 text-white border-transparent"
        },
        secondary: {
          base:
            "hover:bg-gray-400 focus:ring-gray-400 focus:ring-opacity-50 focus:ring-offset-gray-100",
          outline: " border-gray-300 text-gray-500 hover:text-white",
          noOutline: " bg-gray-400 text-white border-transparent"
        },
        white: {
          base:
            "hover:bg-gray-50 focus:ring-gray-200 focus:ring-opacity-50 focus:ring-offset-gray-100 shadow-sm",
          outline: " border-gray-500 text-gray-500 hover:text-white",
          noOutline:
            " bg-white text-gray-800 border border-gray-300 border-transparent"
        },
        dark: {
          base:
            "hover:bg-gray-700 focus:ring-gray-700 focus:ring-opacity-50 focus:ring-offset-gray-100",
          outline: " border-gray-800 text-gray-800 hover:text-white",
          noOutline: " bg-gray-800 text-white border-transparent"
        },
        danger: {
          base:
            "hover:bg-red-600 focus:ring-red-600 focus:ring-opacity-50 focus:ring-offset-red-100",
          outline: " border-red-600 text-red-600 hover:text-white",
          noOutline: " bg-red-500 text-white border-transparent"
        },
        success: {
          base:
            "hover:bg-green-600 focus:ring-green-600 focus:ring-opacity-50 focus:ring-offset-green-100",
          outline: " border-green-600 text-green-600 hover:text-white",
          noOutline: " bg-green-500 text-white border-transparent"
        }
      }
    };
  },

  computed: {
    outlineClass() {
      return this.outline ? "outline" : "noOutline";
    }
  }
};
</script>
