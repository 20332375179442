import BoersenkursService from "../../services/BoersenkursService.js";

export const namespaced = true;

export const state = {
  grammMarge: null
};

export const mutations = {
  SET_GRAMM_MARGE(state, grammMarge) {
    state.grammMarge = grammMarge;
  }
};

export const actions = {
  async grammMarge({ commit }) {
    const resp = await BoersenkursService.grammMarge();
    commit("SET_GRAMM_MARGE", resp.data);
  }
};
