import axios from "axios";
import store from "../store/index.js";

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_SERVER}/api/mitarbeiter`,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

apiClient.interceptors.response.use(
  res => res,
  error => {
    let message = null;
    if (error.response && error.response.status == 401) {
      window.location.href = "/login";
    }
    if (error.response.data.message) {
      message = error.response.data.message;
    } else if (error.response.errors) {
      message = error.response.errors;
    } else if (error.response.message) {
      message = error.response.message;
    } else {
      message = "Es ist ein unbekannter Fehler aufgetreten";
    }

    store.dispatch("notification/add", { error: true, message: message });

    throw error;
  }
);

export default apiClient;
