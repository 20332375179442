<template>
  <div class="pb-10">
    <div class="border-b">
      <BaseContainer>
        <div class="flex flex-wrap justify-between">
          <div class="flex flex-col justify-center">
            <p class="font-medium">Ankauf</p>
          </div>
          <div class="flex space-x-6">
            <BaseButton
              @click.native="showModalEinstellungen = true"
              variant="dark"
              :small="true"
              :outline="true"
              ><SvgSetting class="relative w-4 h-4 -top-px" /> Ankauf
              Einstellungen</BaseButton
            >
          </div>
        </div>
      </BaseContainer>
    </div>
    <BaseContainer>
      <div class="grid grid-cols-1 gap-12 2xl:grid-cols-8 2xl:gap-0 2xl:-mx-7">
        <div
          class="order-last col-span-1 2xl:px-7 2xl:border-r 2xl:order-none 2xl:col-span-5"
        >
          <div class="p-4 mb-6 -m-4">
            <BaseInfo v-if="!ankauf.kunde.email_verified_at" class="mb-6">
              <span class="text-base"
                >Der Kunde hat seine E-Mail Adresse noch nicht
                verifiziert.</span
              >
            </BaseInfo>
            <table class="table-auto">
              <tbody>
                <tr>
                  <td class="font-normal text-gray-600 align-top w-28">
                    <h5>Ankauf ID:</h5>
                  </td>
                  <td>
                    <h5>{{ ankauf.ankauf_id }}</h5>
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-600">Gestartet am:</td>
                  <td>{{ ankauf.gestartet }} Uhr</td>
                </tr>
                <tr>
                  <td class="text-gray-600">Versandart:</td>
                  <td>
                    {{ ankauf.versandartName
                    }}<VersandStatus :ankauf="ankauf" class="ml-1" />
                  </td>
                </tr>
                <tr>
                  <td class="text-gray-600">Status:</td>
                  <td><AnkaufStatus :status="ankauf.status" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <BaseDashboardContainer class="mb-10">
              <template v-slot:header>
                <div class="flex justify-between">
                  <h4>Kunde</h4>
                  <BaseButton :small="true" variant="white"
                    ><SvgUser class="w-4 h-4 mr-1" />Kunde anziegen</BaseButton
                  >
                </div>
              </template>
              <div class="overflow-x-auto">
                <table class="table-auto">
                  <tbody>
                    <tr>
                      <td class="font-normal text-gray-600 align-top w-28">
                        <h5>Name:</h5>
                      </td>
                      <td>
                        <h5>
                          {{ ankauf.kunde.anrede }} {{ ankauf.kunde.firma }}
                          {{ ankauf.kunde.vorname }}
                          {{ ankauf.kunde.nachname }}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-600 align-top">
                        Anschrift:
                      </td>
                      <td>
                        <p>
                          {{ ankauf.adresse.firma }}
                          {{ ankauf.adresse.vorname }}
                          {{ ankauf.adresse.nachname }}
                        </p>
                        <p>
                          {{ ankauf.adresse.strasse }}
                          {{ ankauf.adresse.hausnummer }}
                        </p>
                        <p>{{ ankauf.adresse.plz }} {{ ankauf.adresse.ort }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-600">E-Mail:</td>
                      <td>{{ ankauf.kunde.email }}</td>
                    </tr>
                    <tr>
                      <td class="text-gray-600">Telefon:</td>
                      <td>
                        {{ ankauf.kunde.telefon ? ankauf.kunde.telefon : "-" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-600 align-top">
                        Ankäufe:
                      </td>
                      <td>
                        <div class="flex items-center space-x-2">
                          {{ ankauf.kunde.ankaeufe_count }} Stück
                          <span
                            v-if="ankauf.kunde.ankaeufe_count != 1"
                            class="relative flex w-3 h-3 ml-2"
                          >
                            <span
                              class="absolute inline-flex w-full h-full bg-yellow-500 rounded-full opacity-75 animate-ping"
                            ></span>
                            <span
                              class="relative inline-flex w-3 h-3 bg-yellow-500 rounded-full"
                            ></span>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <template v-slot:footer>
                <div class="flex justify-between">
                  <BaseButton :small="true" variant="white"
                    ><SvgSend class="w-4 h-4 mr-2" />E-Mail</BaseButton
                  >
                  <BaseButton :small="true" variant="white"
                    ><SvgEnvelope class="w-4 h-4 mr-2" />Anschreiben</BaseButton
                  >
                </div>
              </template>
            </BaseDashboardContainer>
            <AnkaufVersand v-if="!ankauf.paketeingang" :ankauf="ankauf" />
            <AnkaufPaketeingang v-if="!ankauf.paketeingang" :ankauf="ankauf" />
            <AnkaufAuszahlung
              v-if="ankauf.status == 'abgeschlossen'"
              :ankauf="ankauf"
            />
            <AnkaufAngebot v-if="ankauf.analyse" :ankauf="ankauf" />
            <AnkaufAnalyse v-if="ankauf.paketeingang" :ankauf="ankauf" />
          </div>
        </div>
        <div class="order-last col-span-1 2xl:px-7 2xl:col-span-3">
          <AnkaufEreignisse :ankauf="ankauf" />
          <AnkaufDokumente :ankauf="ankauf" />
          <AnkaufEmails :ankauf="ankauf" />
          <AnkaufBerechnungen :ankauf="ankauf" />
        </div></div
    ></BaseContainer>

    <!-- Modal Einstellungen -->
    <BaseModal
      :visible="showModalEinstellungen"
      @hide="showModalEinstellungen = false"
    >
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Einstellungen</h4>
        </template>
        <div class="grid grid-cols-1 gap-6">
          <div v-if="ankauf.status != 'archiviert' && ankauf.status == 'aktiv'">
            <p class="font-medium">Ankauf archivieren</p>
            <p class="text-gray-600">
              Archivierte Ankäufe erscheinen nicht im Loginbereich des Kunden.
            </p>
            <BaseButton
              @click.native="archivieren"
              class="mt-4"
              variant="danger"
              >Ankauf archivieren</BaseButton
            >
          </div>
          <div v-else-if="ankauf.status == 'archiviert'">
            <p class="font-medium">Ankauf reaktivieren</p>
            <p class="text-gray-600">
              Wenn du diesen Ankauf reaktiviert kann er wieder bearbeitet werden
              und ist im Kundenlogin sichtbar.
            </p>
            <BaseButton @click.native="aktivieren" class="mt-4" variant="danger"
              >Ankauf reaktivieren</BaseButton
            >
          </div>
          <div
            v-else-if="
              ankauf.status == 'abgeschlossen' &&
                !ankauf.lagerbestandAktualisiert
            "
          >
            <p class="font-medium">Lagerbetand aktualisieren</p>
            <p class="text-gray-600">
              Die angekauften Edelmetalle wurden noch nicht zum Lagerbestand
              hinzugefügt. In der Regel sollte dies passieren.
            </p>
            <div class="mt-5 overflow-auto -mx-7">
              <table
                class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
              >
                <thead>
                  <tr class="text-left">
                    <BaseTh></BaseTh>
                    <BaseTh>Gegenstand</BaseTh>
                    <BaseTh>Edelmetall</BaseTh>
                    <BaseTh>Legierung</BaseTh>
                    <BaseTh>Menge</BaseTh>
                    <BaseTh>Einheit</BaseTh>
                    <BaseTh>Preis Gesamt</BaseTh>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(analyse, index) in ankauf.analysen"
                    :key="index"
                    @click="lagerbestandHinzufuegen(analyse)"
                    class="cursor-pointer"
                  >
                    <BaseTd
                      ><input
                        :checked="lagerbestandPositionen.includes(analyse.id)"
                        type="checkbox"
                    /></BaseTd>
                    <BaseTd>{{ analyse.gegenstand }}</BaseTd>
                    <BaseTd
                      ><span v-if="analyse.edelmetall">{{
                        analyse.edelmetall | ucfirst
                      }}</span></BaseTd
                    >
                    <BaseTd>{{ analyse.legierung }}</BaseTd>
                    <BaseTd>{{ parseFloat(analyse.menge) }}</BaseTd>
                    <BaseTd>{{ analyse.einheit | ucfirst }}</BaseTd>
                    <BaseTd>{{ analyse.preis_gesamt | toCurrency }}</BaseTd>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <p class="text-center">Es gibt nichts einzustellen</p>
          </div>
        </div>
        <template
          v-if="
            ankauf.status == 'abgeschlossen' && !ankauf.lagerbestandAktualisiert
          "
          v-slot:footer
        >
          <div class="flex justify-end">
            <BaseButton
              @click.native="lagerbestand"
              :loading="loadingLagerbestand"
              variant="dark"
              >Ausgewählte Positionen hinzufügen</BaseButton
            >
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Einstellungen ENDE -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import AnkaufStatus from "../components/Ankauf/AnkaufStatus.vue";
import AnkaufDokumente from "../components/Ankauf/AnkaufDokumente.vue";
import VersandStatus from "../components/Ankauf/VersandStatus.vue";
import AnkaufEreignisse from "../components/Ankauf/AnkaufEreignisse.vue";
import AnkaufBerechnungen from "../components/Ankauf/AnkaufBerechnungen.vue";
import AnkaufEmails from "../components/Ankauf/AnkaufEmails.vue";
import AnkaufVersand from "../components/Ankauf/Verwalten/AnkaufVersand.vue";
import AnkaufPaketeingang from "../components/Ankauf/Verwalten/AnkaufPaketeingang.vue";
import AnkaufAnalyse from "../components/Ankauf/Verwalten/AnkaufAnalyse.vue";
import AnkaufAngebot from "../components/Ankauf/Verwalten/AnkaufAngebot.vue";
import AnkaufAuszahlung from "../components/Ankauf/Verwalten/AnkaufAuszahlung.vue";
import AnkaufStatusService from "../services/AnkaufStatusService.js";
import LagerbestandService from "../services/LagerbestandService.js";

export default {
  components: {
    AnkaufStatus,
    AnkaufDokumente,
    VersandStatus,
    AnkaufEreignisse,
    AnkaufBerechnungen,
    AnkaufEmails,
    AnkaufVersand,
    AnkaufPaketeingang,
    AnkaufAnalyse,
    AnkaufAngebot,
    AnkaufAuszahlung
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data() {
    return {
      showModalEinstellungen: false,
      lagerbestandPositionen: [],
      loadingLagerbestand: false
    };
  },

  computed: {
    ...mapState({
      ankauf: state => state.ankauf.ankauf
    })
  },

  methods: {
    async archivieren() {
      await AnkaufStatusService.archiviert({ ankauf_id: this.ankauf.id });
      this.showModalEinstellungen = false;
      this.$store.dispatch("ankauf/show", this.ankauf.id);
    },

    async aktivieren() {
      await AnkaufStatusService.aktiv({ ankauf_id: this.ankauf.id });
      this.showModalEinstellungen = false;
      this.$store.dispatch("ankauf/show", this.ankauf.id);
    },

    lagerbestandHinzufuegen(position) {
      if (!this.lagerbestandPositionen.includes(position.id)) {
        this.lagerbestandPositionen.push(position.id);
      } else {
        let index = this.lagerbestandPositionen.indexOf(position.id);
        this.lagerbestandPositionen.splice(index, 1);
      }
    },

    async lagerbestand() {
      this.loadingLagerbestand = true;
      await LagerbestandService.store({
        ankauf_id: this.ankauf.id,
        positionen: this.lagerbestandPositionen
      });

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.showModalEinstellungen = false;
      this.loadingLagerbestand = false;
    }
  }
};
</script>

<style scoped>
table tr td {
  padding: 1px 5px;
}
</style>
