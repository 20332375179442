<template>
  <textarea
    @input="$emit('input', $event.target.value)"
    :value="value"
    rows="5"
    class="px-2 py-1 border border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
  ></textarea>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ""
    }
  }
};
</script>

<style></style>
