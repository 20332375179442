import apiClient from "./ApiClient.js";

export default {
  csrf() {
    return apiClient.get("/csrf");
  },

  loeschen(data) {
    return apiClient.post("/dokument/destroy", data);
  },

  bearbeiten(data) {
    return apiClient.post("/dokument/update", data);
  },

  versandetikett(ankauf_id) {
    return apiClient.get("/versandetikett/" + ankauf_id);
  }
};
