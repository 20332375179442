<template>
  <div
    class="px-6 py-4 rounded-md shadow-sm sm:flex sm:items-start"
    :class="{
      'text-brand-800 bg-brand-50': variant == 'brand',
      'text-gray-800 bg-gray-100': variant == 'secondary',
      'text-green-800 bg-green-100': variant == 'success',
      'text-yellow-800 bg-yellow-100': variant == 'warning',
      'text-red-800 bg-red-100': variant == 'danger'
    }"
  >
    <SvgCheck v-if="success" class="flex-shrink-0 w-8 h-8" />
    <SvgWarning v-else class="flex-shrink-0 w-8 h-8" />
    <p class="text-sm sm:ml-6"><slot></slot></p>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      default: false
    },

    variant: {
      default: "danger",
      type: String,
      validator: value => {
        return ["brand", "secondary", "success", "warning", "danger"].includes(
          value
        );
      }
    }
  }
};
</script>
