import apiClient from "./ApiClient.js";

export default {
  async vergessen(data) {
    await apiClient({
      url: "/sanctum/csrf-cookie",
      baseURL: process.env.VUE_APP_API_SERVER
    });

    return apiClient({
      method: "POST",
      url: "/mitarbeiter/passwort-vergessen",
      baseURL: process.env.VUE_APP_API_SERVER,
      params: data
    });
  },

  async wiederherstellen(data) {
    await apiClient({
      url: "/sanctum/csrf-cookie",
      baseURL: process.env.VUE_APP_API_SERVER
    });

    return apiClient({
      method: "POST",
      url: "/mitarbeiter/passwort-zuruecksetzen",
      baseURL: process.env.VUE_APP_API_SERVER,
      params: data
    });
  }
};
