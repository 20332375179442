<template>
  <a
    :class="{
      'bg-gray-200 text-gray-900': active,
      'hover:text-gray-900': !active
    }"
    class="block px-4 py-3 mt-1 text-sm font-semibold text-gray-700 transition-all rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none"
    ><slot></slot
  ></a>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>
