export const namespaced = true;

export const state = {
  open: false
};

export const mutations = {
  SET_OPEN(state, open) {
    state.open = open;
  }
};

export const actions = {
  toggle({ commit }, open) {
    commit("SET_OPEN", open);
  }
};
