import apiClient from "./ApiClient.js";

export default {
  store(update = false, data) {
    if (update) {
      return apiClient.post("/analyse/update", data);
    }
    return apiClient.post("/analyse", data);
  }
};
