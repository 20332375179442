import apiClient from "./ApiClient.js";

export default {
  aktiv(data) {
    return apiClient.post("/ankaufstatus/aktiv", data);
  },

  abgeschlossen(data) {
    return apiClient.post("/ankaufstatus/abgeschlossen", data);
  },

  abgebrochen(data) {
    return apiClient.post("/ankaufstatus/abgebrochen", data);
  },

  archiviert(data) {
    return apiClient.post("/ankaufstatus/archiviert", data);
  }
};
