<template>
  <div class="flex-col w-full bg-gray-100 lg:flex lg:flex-row lg:h-screen">
    <div
      class="flex flex-col flex-shrink-0 w-full bg-white lg:border-r lg:w-72"
    >
      <div
        class="flex flex-row items-center justify-between flex-shrink-0 px-4 py-6 md:px-16 lg:px-8"
      >
        <div>
          <p class="font-semibold text-brand-600">
            CUGA Deutschland GmbH
          </p>
          <p>{{ user.vorname }} {{ user.nachname }}</p>
        </div>
        <button
          class="rounded-lg lg:hidden focus:outline-none focus:shadow-outline"
          @click="toggleMenu(!open)"
        >
          <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
            <path
              v-show="!open"
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
            <path
              v-show="open"
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <nav
        :class="{ block: open, hidden: !open }"
        class="flex-grow p-4 shadow lg:shadow-none lg:block lg:pb-0 lg:overflow-y-auto"
      >
        <router-link to="/"
          ><NavItem :active="$route.name == 'Home'"
            ><SvgHome
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Übersicht</NavItem
          ></router-link
        >
        <router-link to="/ankaeufe"
          ><NavItem :active="$route.name == 'Ankaeufe'"
            ><SvgList
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Ankäufe</NavItem
          ></router-link
        >
        <router-link to="/kunden"
          ><NavItem :active="$route.name == 'Kunden'"
            ><SvgUsers
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Kunden</NavItem
          ></router-link
        >
        <router-link to="/lagerbestand"
          ><NavItem :active="$route.name == 'Lagerbestand'"
            ><SvgStorage
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Lagerbestand</NavItem
          ></router-link
        >
        <router-link to="/berechnungen"
          ><NavItem :active="$route.name == 'Berechnungen'"
            ><SvgCalculator
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Berechnungen</NavItem
          ></router-link
        >
        <router-link to="/dokumente"
          ><NavItem :active="$route.name == 'Dokumente'"
            ><SvgFile
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Dokumente</NavItem
          ></router-link
        >
        <div class="my-4 -mx-4 border-t"></div>
        <router-link to="/einstellungen"
          ><NavItem :active="$route.name == 'Einstellungen'"
            ><SvgSetting
              class="inline w-5 h-5 mr-2 text-gray-700"
            />Einstellungen</NavItem
          ></router-link
        >
        <NavItem
          @click.native="logout"
          class="cursor-pointer lg:absolute bottom-4"
          ><SvgLogout
            class="inline w-5 h-5 mr-2 text-gray-700"
          />Ausloggen</NavItem
        >
      </nav>
    </div>
    <div class="w-full lg:overflow-y-scroll">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavItem from "@/components/miscellaneous/NavItem.vue";

export default {
  components: {
    NavItem
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      open: state => state.menu.open
    })
  },

  methods: {
    toggleMenu(open) {
      this.$store.dispatch("menu/toggle", open);
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.replace({ name: "Login" });
      });
    }
  }
};
</script>

<style></style>
