<template>
  <div
    v-if="
      ankauf.versandart == 'werttransport' ||
        ankauf.versandart == 'versandmaterial'
    "
    class="mb-6"
  >
    <BaseDashboardContainer v-if="ankauf.versandart == 'werttransport'">
      <template v-slot:header>
        <h4>Werttransport vorbereiten</h4>
      </template>
      <div class="-m-6">
        <div class="flex justify-between px-6 py-4 bg-gray-50">
          <p>
            <SvgClose
              v-if="!angerufen"
              class="-ml-1 text-red-600 w-7 h-7"
            /><SvgCheck
              v-if="angerufen"
              class="-ml-1 text-green-600 w-7 h-7"
            />Kunde angerufen
          </p>
          <BaseToggle v-model="angerufen" />
        </div>
        <div class="flex justify-between px-6 py-4">
          <p>
            <SvgClose
              v-if="!beauftragt"
              class="-ml-1 text-red-600 w-7 h-7"
            /><SvgCheck
              v-if="beauftragt"
              class="-ml-1 text-green-600 w-7 h-7"
            />Werttransport beauftragt
          </p>
          <BaseToggle v-model="beauftragt" />
        </div>
      </div>
    </BaseDashboardContainer>

    <BaseDashboardContainer v-else-if="ankauf.versandart == 'versandmaterial'">
      <template v-slot:header>
        <div class="flex justify-between">
          <h4>Versandmaterial verschicken</h4>
          <BaseButton
            @click.native="versandetikett"
            :loading="loading"
            :small="true"
            variant="white"
            >Versandetikett erstellen</BaseButton
          >
        </div>
      </template>
      <div class="-m-6">
        <div class="flex justify-between px-6 py-4 bg-gray-50">
          <p>
            <SvgClose
              v-if="!verschickt"
              class="-ml-1 text-red-600 w-7 h-7"
            /><SvgCheck
              v-if="verschickt"
              class="-ml-1 text-green-600 w-7 h-7"
            />Versandmaterial verschickt
          </p>
          <BaseToggle v-model="verschickt" />
        </div>
      </div>
    </BaseDashboardContainer>
  </div>
</template>

<script>
import AnkaufEreignisService from "../../../services/AnkaufEreignisService.js";
import DokumentService from "../../../services/DokumentService.js";

export default {
  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      angerufen: this.ankauf.werttransport
        ? this.ankauf.werttransport.angerufen
        : null,
      beauftragt: this.ankauf.werttransport
        ? this.ankauf.werttransport.beauftragt
        : null,
      verschickt: this.ankauf.versandmaterial
        ? this.ankauf.versandmaterial.verschickt
        : null
    };
  },

  watch: {
    async verschickt(newVal) {
      await AnkaufEreignisService.verschickt({
        ankauf_id: this.ankauf.id,
        verschickt: newVal
      });
      this.$store.dispatch("ankauf/show", this.ankauf.id);
    },

    async angerufen(newVal) {
      await AnkaufEreignisService.angerufen({
        ankauf_id: this.ankauf.id,
        angerufen: newVal
      });
      this.$store.dispatch("ankauf/show", this.ankauf.id);
    },

    async beauftragt(newVal) {
      await AnkaufEreignisService.beauftragt({
        ankauf_id: this.ankauf.id,
        beauftragt: newVal
      });
      this.$store.dispatch("ankauf/show", this.ankauf.id);
    }
  },

  methods: {
    async versandetikett() {
      this.loading = true;
      await DokumentService.versandetikett(this.ankauf.id);
      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loading = false;
    }
  }
};
</script>

<style></style>
