<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
    <NotificationContainer />
  </div>
</template>

<script>
import NotificationContainer from "@/components/Notification/NotificationContainer.vue";

export default {
  components: {
    NotificationContainer
  }
};
</script>
