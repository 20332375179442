<template>
  <div
    :class="notification.error ? 'bg-red-400' : 'bg-lightBlue-400'"
    class="relative flex px-8 py-4 mb-4 font-medium text-white transition-all transform rounded shadow-xl justify-betweenmax-w-sm"
  >
    <p v-html="notification.message"></p>
    <div @click="remove(notification)" class="absolute top-2 right-2">
      <SvgClose class="w-4 h-4 text-black" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      timeout: null
    };
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 15000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: mapActions("notification", ["remove"])
};
</script>

<style></style>
