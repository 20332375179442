import MargeService from "../../services/MargeService.js";

export const namespaced = true;

export const state = {
  marge: {},
  margen: {}
};

export const mutations = {
  SET_MARGE(state, marge) {
    state.marge = marge;
  },

  SET_MARGEN(state, margen) {
    state.margen = margen;
  }
};

export const actions = {
  async show({ commit }) {
    const resp = await MargeService.show();
    commit("SET_MARGE", resp.data);
  },

  async index({ commit }) {
    const resp = await MargeService.index();
    commit("SET_MARGEN", resp.data);
  }
};
