<template>
  <div class="relative">
    <div class="border-b">
      <BaseContainer>
        <div class="flex flex-wrap justify-between">
          <div class="flex flex-col justify-center">
            <p class="font-medium">Ankäufe</p>
          </div>
          <div class="flex space-x-6">
            <BaseButton
              @click.native="showFilter = true"
              variant="white"
              :small="true"
            >
              Filter öffnen<SvgFilter class="inline w-5 h-4 ml-2" />
            </BaseButton>
            <BaseButton variant="dark" :small="true" :outline="true"
              >Ankauf anlegen</BaseButton
            >
          </div>
        </div>
      </BaseContainer>
    </div>
    <BaseContainer>
      <p class="mb-4 text-gray-600">Schnellfilter</p>
      <div class="grid grid-cols-1 gap-4 md:grid-cols-4 xl:grid-cols-8">
        <div
          :class="{ 'shadow-md border-brand-300': filter.status == 'aktiv' }"
          class="flex w-full col-span-2 bg-white border rounded shadow cursor-pointer"
          @click="quickFilter('aktiv')"
        >
          <div
            class="flex flex-col justify-center flex-grow-0 p-4 text-blue-700 bg-blue-200 rounded-l"
          >
            <SvgDots v-if="!loadingAktiv" class="w-5 h-5" />
            <SvgSpinner :custom="true" v-else class="w-5 h-5" />
          </div>
          <div class="flex-1 px-6 py-2 rounded-r">
            <p class="font-semibold">Aktiv</p>
            <p class="text-sm text-gray-600">{{ ankaufCount.aktiv }} Stück</p>
          </div>
        </div>
        <div
          :class="{
            'shadow-md border-brand-300': filter.status == 'abgeschlossen'
          }"
          class="flex w-full col-span-2 bg-white border rounded-md shadow cursor-pointer"
          @click="quickFilter('abgeschlossen')"
        >
          <div
            class="flex flex-col justify-center flex-grow-0 p-4 text-green-700 bg-green-200 rounded-l"
          >
            <SvgCheck v-if="!loadingAbgeschlossen" class="w-5 h-5" />
            <SvgSpinner :custom="true" v-else class="w-5 h-5" />
          </div>
          <div class="flex-1 px-6 py-2 rounded-r">
            <p class="font-semibold">Abgeschlossen</p>
            <p class="text-sm text-gray-600">
              {{ ankaufCount.abgeschlossen }} Stück
            </p>
          </div>
        </div>
        <div
          :class="{
            'shadow-md border-brand-300': filter.status == 'abgebrochen'
          }"
          class="flex w-full col-span-2 bg-white border rounded-md shadow cursor-pointer"
          @click="quickFilter('abgebrochen')"
        >
          <div
            class="flex flex-col justify-center flex-grow-0 p-4 text-red-700 bg-red-200 rounded-l"
          >
            <SvgStop v-if="!loadingAbgebrochen" class="w-5 h-5" />
            <SvgSpinner :custom="true" v-else class="w-5 h-5" />
          </div>
          <div class="flex-1 px-6 py-2 rounded-r">
            <p class="font-semibold">Abgebrochen</p>
            <p class="text-sm text-gray-600">
              {{ ankaufCount.abgebrochen }} Stück
            </p>
          </div>
        </div>
        <div
          :class="{
            'shadow-md border-brand-300': filter.status == 'archiviert'
          }"
          class="flex w-full col-span-2 bg-white border rounded-md shadow cursor-pointer"
          @click="quickFilter('archiviert')"
        >
          <div
            class="flex flex-col justify-center flex-grow-0 p-4 text-gray-700 bg-gray-200 rounded-l"
          >
            <SvgTrash v-if="!loadingArchiviert" class="w-5 h-5" />
            <SvgSpinner :custom="true" v-else class="w-5 h-5" />
          </div>
          <div class="flex-1 px-6 py-2 rounded-r">
            <p class="font-semibold">Archiviert</p>
            <p class="text-sm text-gray-600">
              {{ ankaufCount.archiviert }} Stück
            </p>
          </div>
        </div>
      </div>
    </BaseContainer>
    <div class="relative overflow-x-auto overflow-y-auto">
      <table
        class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
      >
        <thead class="whitespace-nowrap">
          <tr class="text-left">
            <BaseTh><SvgUser class="inline w-5 h-5 mr-1" />Kunde</BaseTh>
            <BaseTh><SvgTruck class="inline w-5 h-5 mr-1" />Versandart</BaseTh>
            <BaseTh
              ><SvgFingerprint class="inline w-5 h-5 mr-1" />Ankauf ID</BaseTh
            >
            <BaseTh
              ><SvgCalendar class="inline w-5 h-5 mr-1" />Gestartet</BaseTh
            >
          </tr>
        </thead>
        <tbody v-if="ankaeufe">
          <tr
            v-for="(ankauf, index) in ankaeufe.data"
            :key="index"
            @click="ankaufOeffnen(ankauf.id)"
            class="transition-colors border-l-8 cursor-pointer whitespace-nowrap hover:bg-white"
            :class="{
              'border-blue-400': ankauf.status == 'aktiv',
              'border-green-400': ankauf.status == 'abgeschlossen',
              'border-red-400': ankauf.status == 'abgebrochen',
              'border-gray-400': ankauf.status == 'archiviert',
              'opacity-60': !ankauf.kunde.email_verified_at
            }"
          >
            <BaseTd
              >{{ ankauf.kunde.anrede }} {{ ankauf.kunde.firma }}
              {{ ankauf.kunde.vorname }}
              {{ ankauf.kunde.nachname }}</BaseTd
            >
            <BaseTd>
              <VersandStatus :ankauf="ankauf" class="mr-2" />
              {{ ankauf.versandartName }}
            </BaseTd>
            <BaseTd>{{ ankauf.ankauf_id }}</BaseTd>
            <BaseTd class="text-sm">{{ ankauf.gestartet }} Uhr</BaseTd>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-6">
      <p class="mb-2 text-sm text-center text-gray-600">
        {{ verbleibend }} Ankäufe verbleibend
      </p>
      <BaseButton
        v-show="verbleibend > 0"
        :loading="loadingMore"
        @click.native="weiter"
        variant="dark"
        :outline="true"
        class="mx-auto"
        >mehr anzeigen</BaseButton
      >
    </div>
    <TransitionFadeScale>
      <div
        v-show="showFilter"
        class="absolute top-0 right-0 z-40 block w-full transition-all transform bg-white rounded-md shadow-2xl lg:w-9/12 xl:w-7/12 lg:right-16 sm:top-20"
      >
        <div class="p-8">
          <h4 class="mb-4">Filter</h4>
          <div class="flex flex-col gap-4 pb-6 mb-4 border-b md:flex-row">
            <div class="flex-1">
              <label
                for="ankauf_id"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Ankauf ID</label
              >
              <BaseInput
                v-model="filter.ankauf_id"
                id="ankauf_id"
                class="w-full h-11"
              />
            </div>
            <div class="flex-1">
              <label class="block mb-1 text-sm font-medium text-gray-600"
                >Status</label
              >
              <BaseSelect v-model="filter.status" class="w-full h-11">
                <option :value="null">Alle</option>
                <option value="aktiv">Aktiv</option>
                <option value="abgeschlossen">Abgeschlossen</option>
                <option value="abgebrochen">Abgebrochen</option>
                <option value="archiviert">Archiviert</option>
              </BaseSelect>
            </div>
            <div class="flex-1">
              <label class="block mb-1 text-sm font-medium text-gray-600"
                >Versandart</label
              >
              <BaseSelect v-model="filter.versandart" class="w-full h-11">
                <option :value="null">Alle</option>
                <option value="eigenes-material">Eigenes Material</option>
                <option value="werttransport">Werttransport</option>
                <option value="ersandmaterial">Versandmaterial</option>
              </BaseSelect>
            </div>
          </div>

          <div class="flex flex-col gap-4 pb-4 mb-4 border-b md:flex-row">
            <div class="flex-1">
              <label
                for="name"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Kundenname</label
              >
              <BaseInput v-model="filter.name" id="name" class="w-full h-11" />
            </div>
            <div class="flex-1">
              <label
                for="email"
                class="block mb-1 text-sm font-medium text-gray-600"
                >E-Mail Adresse</label
              >
              <BaseInput
                v-model="filter.email"
                id="email"
                class="w-full h-11"
              />
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-baseline justify-between gap-4 px-8 py-4 border-t rounded-b sm:flex-row bg-gray-50"
        >
          <p
            @click="resetFilter"
            class="inline mt-1 text-sm font-medium text-red-400 transition-colors cursor-pointer hover:text-red-600"
          >
            Filter zurücksetzen
          </p>
          <BaseButton
            @click.native="getAnkaeufe"
            class="order-first w-full sm:w-auto sm:order-last"
            type="submit"
            >Filter anwenden</BaseButton
          >
        </div>
      </div>
    </TransitionFadeScale>
    <TransitionFade>
      <div
        v-show="showFilter"
        @click="showFilter = false"
        class="fixed inset-0 transition-all"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-40"></div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import AnkaufService from "../services/AnkaufService.js";
import TransitionFadeScale from "../components/Transitions/TransitionFadeScale.vue";
import TransitionFade from "../components/Transitions/TransitionFade.vue";
import VersandStatus from "../components/Ankauf/VersandStatus.vue";

export default {
  components: {
    TransitionFade,
    TransitionFadeScale,
    VersandStatus
  },

  data() {
    return {
      ankaeufe: this.$store.state.ankauf.ankaeufe,
      filter: {
        page: 1,
        status: "aktiv",
        ankauf_id: null,
        versandart: null,
        name: null,
        email: null,
        dateAfter: null,
        dateBefore: null
      },
      filterDefault: { ...this.filter },
      loadingMore: false,
      loadingAktiv: false,
      loadingAbgeschlossen: false,
      loadingAbgebrochen: false,
      loadingArchiviert: false,
      showFilter: false,
      ankaufCount: this.$store.state.stats.ankaufCount
    };
  },

  computed: {
    verbleibend() {
      if (this.ankaeufe) {
        return this.ankaeufe.total - this.ankaeufe.data.length;
      }
      return null;
    }
  },

  methods: {
    async weiter() {
      if (this.filter.page < this.ankaeufe.last_page) {
        this.loadingMore = true;
        this.filter.page++;
        const resp = await this.fetch();
        this.ankaeufe.data.push(...resp.data);
        this.loadingMore = false;
      }
    },

    async quickFilter(status) {
      this[`loading${status[0].toUpperCase() + status.slice(1)}`] = true;
      this.filter = { ...this.filterDefault };
      this.filter.status = status;
      this.filter.page = 1;
      await this.getAnkaeufe();
      this[`loading${status[0].toUpperCase() + status.slice(1)}`] = false;
    },

    resetFilter() {
      this.filter = { ...this.filterDefault };
      this.getAnkaeufe();
    },

    async getAnkaeufe() {
      this.showFilter = false;
      const resp = await this.fetch();
      this.ankaeufe = resp;
    },

    async fetch() {
      const resp = await AnkaufService.getAnkaeufe(this.filter);
      return resp.data;
    },

    ankaufOeffnen(id) {
      this.$router.push({ path: `/ankauf/${id}` });
    }
  }
};
</script>

<style></style>
