<template>
  <select
    @input="$emit('input', $event.target.value)"
    :value="value"
    class="px-2 py-1 border border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
  >
    <slot></slot>
  </select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    }
  }
};
</script>

<style></style>
