<template>
  <div>
    <BaseDashboardContainer class="mt-10">
      <template v-slot:header>
        <h4>Dokumente</h4>
      </template>

      <div class="-m-6 overflow-x-auto">
        <table
          class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
        >
          <thead>
            <tr class="text-left">
              <BaseTh>Name</BaseTh>
              <BaseTh>Dateityp</BaseTh>
              <BaseTh>Kundenlogin</BaseTh>
              <BaseTh>Hochgeladen am</BaseTh>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(dokument, index) in ankauf.dokumente"
              :key="index"
              @click="dokumentOeffnen(dokument)"
              class="cursor-pointer hover:bg-gray-50"
            >
              <BaseTd>{{ dokument.dateiname }}</BaseTd>
              <BaseTd>{{ dokument.dokument_typ.name }}</BaseTd>
              <BaseTd
                ><SvgClose
                  v-if="!dokument.kundenlogin"
                  class="-ml-1 text-red-600 w-7 h-7"/><SvgCheck
                  v-else
                  class="-ml-1 text-green-600 w-7 h-7"
              /></BaseTd>
              <BaseTd>{{ dokument.datum }} Uhr</BaseTd>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:footer>
        <BaseButton
          @click.native="showModalUpload = true"
          class="ml-auto"
          variant="white"
          :small="true"
          ><SvgFileUpload class="w-4 h-4 mr-2" />Dokument hochladen</BaseButton
        >
      </template>
    </BaseDashboardContainer>

    <!-- Modal Dokument ansehen -->
    <BaseModal :visible="showModalDokument" @hide="hideModal">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Dokument</h4>
        </template>
        <div v-show="!bearbeiten" class="text-center">
          <h5 class="mb-4">{{ dokument.dateiname }}</h5>
          <BaseButton @click.native="download" variant="white" class="mx-auto"
            ><SvgDownload class="inline w-5 h-5 mr-2" />Download</BaseButton
          >
        </div>
        <div v-show="bearbeiten">
          <div class="grid gap-4 md:grid-cols-3">
            <div>
              <label
                for="dateiname"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Dateiname</label
              >
              <div class="flex items-baseline space-x-2">
                <BaseInput
                  v-model="dokument.name"
                  id="dateiname"
                  class="w-full h-11"
                  :class="{
                    'border-red-500': $v.dokument.dateiname.$error
                  }"
                />
                <p>.{{ dokument.extension }}</p>
              </div>
            </div>
            <div>
              <label
                for="auszahlungsmedium"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Dateityp</label
              >
              <BaseSelect
                v-model="dokument.dokument_typ_id"
                id="auszahlungsmedium"
                class="w-full h-11"
                :class="{
                  'border-red-500': $v.dokument.dokument_typ_id.$error
                }"
              >
                <option value="1">Sonstige</option>
                <option value="2">Eingangsbestätigung</option>
                <option value="3">Begleitschreiben</option>
                <option value="4">Versandetikett</option>
                <option value="5">Gutschrift</option>
              </BaseSelect>
            </div>
            <div>
              <label
                for="auszahlungsmedium"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Kundenlogin sichtbar</label
              >
              <input v-model="dokument.kundenlogin" type="checkbox" />
            </div>
          </div>
        </div>
        <template v-slot:footer>
          <div v-show="!bearbeiten" class="flex justify-end">
            <BaseButton
              @click.native="bearbeiten = true"
              :loading="loading"
              variant="dark"
              >Dokument bearbeiten</BaseButton
            >
          </div>
          <div
            v-show="bearbeiten"
            class="flex flex-col justify-between md:flex-row"
          >
            <BaseButton
              @click.native="dokumentLoeschen"
              variant="danger"
              class="mb-3 md:mb-0"
              >Dokument löschen</BaseButton
            >
            <div
              class="flex flex-col space-x-0 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
            >
              <BaseButton
                @click.native="bearbeiten = false"
                :outline="true"
                variant="dark"
                >abbrechen</BaseButton
              >
              <BaseButton @click.native="dokumentBearbeiten" variant="dark"
                >Änderungen speichern</BaseButton
              >
            </div>
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Dokument ansehen ENDE -->

    <!-- Modal Dokument hochladen -->
    <BaseModal :visible="showModalUpload" @hide="hideModal">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Dokument hochladen</h4>
        </template>
        <div class="-m-6">
          <vue-dropzone
            ref="dropzoneDokumente"
            id="dropzoneDokumente"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-files-added="registerFile"
            @vdropzone-drop="registerFileDrop"
            @vdropzone-sending="sendingEvent"
            @vdropzone-processing="renameFile"
            @vdropzone-complete="fileComplete"
            @vdropzone-error="fileError"
            @vdropzone-queue-complete="uploadComplete"
          >
            <p>
              <SvgUpload class="inline-block w-5 h-5 mr-2" />
              Dateien hierher ziehen oder <u>auswählen</u>
            </p>
          </vue-dropzone>
          <table
            v-show="dateien.length"
            class="relative w-full mb-4 whitespace-no-wrap bg-white border-collapse table-auto table-striped"
          >
            <thead>
              <tr class="p-6 text-left">
                <BaseTh>Dateiname</BaseTh>
                <BaseTh>Dateigröße</BaseTh>
                <BaseTh>Kundenlogin sichtbarkeit</BaseTh>
                <BaseTh>Dateityp</BaseTh>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(datei, index) in dateien" :key="index">
                <BaseTd
                  ><input
                    v-model="datei.name"
                    class="border-b border-gray-300"
                  />.{{ datei.extension }}</BaseTd
                >
                <BaseTd>{{ datei.size | prettyBytes }}</BaseTd>
                <BaseTd
                  ><input v-model="datei.kundenlogin" type="checkbox"
                /></BaseTd>
                <BaseTd>
                  <select
                    v-model="datei.dokument_typ_id"
                    class="border-gray-300 rounded"
                  >
                    <option value="1">Sonstige</option>
                    <option value="2">Eingangsbestätigung</option>
                    <option value="3">Begleitschreiben</option>
                    <option value="4">Versandetikett</option>
                  </select>
                </BaseTd>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <BaseButton @click.native="upload">Dateien hochladen</BaseButton>
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Dokument hochladen ENDE -->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import DokumentService from "../../services/DokumentService.js";
import vue2Dropzone from "vue2-dropzone";
import apiClient from "../../services/ApiClient.js";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },

  props: {
    ankauf: {
      typ: Object,
      required: true
    }
  },

  validations: {
    dokument: {
      dateiname: { required },
      dokument_typ_id: { required }
    }
  },

  data() {
    return {
      showModalDokument: false,
      showModalUpload: false,
      loading: false,
      bearbeiten: false,
      dokument: {},
      dateien: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_SERVER}/api/mitarbeiter/dokument`,
        autoProcessQueue: false,
        maxThumbnailFilesize: 0.2,
        maxFilesize: 15,
        maxFiles: 10,
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": null
        }
      }
    };
  },

  methods: {
    pause(milliseconds = 1000) {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    },

    hideModal() {
      this.showModalDokument = false;
      this.showModalUpload = false;
    },

    dokumentOeffnen(dokument) {
      this.showModalDokument = true;
      this.dokument = { ...dokument };
      this.dokument.extension = dokument.dateiname.slice(
        ((dokument.dateiname.lastIndexOf(".") - 1) >>> 0) + 2
      );
      this.dokument.name = dokument.dateiname.replace(/\.[^/.]+$/, "");
    },

    async download() {
      const response = await apiClient({
        url: "/dokument/" + this.dokument.id,
        method: "GET",
        responseType: "blob"
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.dokument.dateiname);
      document.body.appendChild(link);
      link.click();
    },

    async dokumentBearbeiten() {
      this.bearbeiten = false;
      this.loading = true;
      this.dokument.dateiname =
        this.dokument.name + "." + this.dokument.extension;
      await DokumentService.bearbeiten(this.dokument);
      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loading = false;
      this.showModalDokument = false;
    },

    async dokumentLoeschen() {
      this.bearbeiten = false;
      this.loading = true;
      await DokumentService.loeschen({
        ankauf_id: this.ankauf.id,
        dokument_id: this.dokument.id
      });
      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loading = false;
      this.showModalDokument = false;
    },

    /** Datei Upload related */
    async registerFileDrop() {
      await this.pause(700);
      this.registerFile();
    },

    registerFile() {
      const files = this.$refs.dropzoneDokumente.getAcceptedFiles();
      this.dateien = [];
      files.forEach(file => {
        this.dateien.push({
          uuid: file.upload.uuid,
          extension: file.name.slice(
            ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
          ),
          name: file.name.replace(/\.[^/.]+$/, ""),
          size: file.size,
          dokument_typ_id: 1
        });
      });
    },

    renameFile(file) {
      this.dateien.find(datei => {
        if (datei.uuid === file.upload.uuid) {
          file.upload.filename = datei.name + "." + datei.extension;
        }
      });
    },

    sendingEvent(file, xhr, formData) {
      this.dateien.find(datei => {
        if (datei.uuid === file.upload.uuid) {
          formData.append("dokument_typ_id", datei.dokument_typ_id);
          formData.append("ankauf_id", this.ankauf.id);
          formData.append("kundenlogin", datei.kundenlogin);
        }
      });
    },

    async upload() {
      const csrf = await DokumentService.csrf();
      this.dropzoneOptions.headers["X-CSRF-TOKEN"] = csrf.data;
      if (this.$refs.dropzoneDokumente.getAcceptedFiles().length) {
        this.loading = true;
        this.$refs.dropzoneDokumente.processQueue();
      }
    },

    fileComplete(response) {
      if (response.xhr) {
        const error = response.status == "error" ? true : false;
        const message = error
          ? "Die Datei <u>" +
            response.upload.filename +
            "</u> konnte nicht hochgeladen werden."
          : "Die Datei <u>" +
            response.upload.filename +
            "</u> wurde hochgeladen.";
        this.$store.dispatch("notification/add", {
          error: error,
          message: message
        });
      }
    },

    async uploadComplete() {
      if (this.loading) {
        this.$refs.dropzoneDokumente.removeAllFiles();
        this.dateien = [];
        this.showModalUpload = false;
        this.loading = false;
        this.$store.dispatch("ankauf/show", this.ankauf.id);
      }
    },

    fileError(file, message) {
      this.$store.dispatch("notification/add", {
        message: file.name + ": " + message,
        error: true
      });
      this.$refs.dropzoneDokumente.removeFile(file);
    }
    /** Datei Upload related ENDE */
  }
};
</script>

<style>
.dz-preview {
  min-width: 100px;
}
</style>
