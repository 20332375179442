import apiClient from "./ApiClient.js";

export default {
  show() {
    return apiClient.get("/marge");
  },

  index() {
    return apiClient.get("/margen");
  },

  store(data) {
    return apiClient.post("/marge", data);
  }
};
