import Vue from "vue";
import VueRouter from "vue-router";

import NProgress from "nprogress";
import store from "../store/index.js";
import LayoutApp from "../views/layouts/App.vue";
import LayoutGuest from "../views/layouts/Guest.vue";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Ankaeufe from "../views/Ankaeufe.vue";
import Ankauf from "../views/Ankauf.vue";
import Kunden from "../views/Kunden.vue";
import Lagerbestand from "../views/Lagerbestand.vue";
import Berechnungen from "../views/Berechnungen.vue";
import Berechnung from "../views/Berechnung.vue";
import Dokumente from "../views/Dokumente.vue";
import Einstellungen from "../views/Einstellungen.vue";
import NotFound from "../views/NotFound.vue";
import PasswortVergessen from "../views/PasswortVergessen.vue";
import PasswortWiederherstellen from "../views/PasswortWiederherstellen.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: LayoutGuest,
      requiresAuth: false
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    }
  },
  {
    path: "/ankaeufe",
    name: "Ankaeufe",
    component: Ankaeufe,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    },
    async beforeEnter(routeTo, routeFrom, next) {
      await store.dispatch("stats/ankaufCount");
      await store.dispatch("ankauf/index", { status: "aktiv" });
      next();
    }
  },
  {
    path: "/ankauf/:id",
    name: "Ankauf",
    component: Ankauf,
    props: true,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    },
    async beforeEnter(routeTo, routeFrom, next) {
      await store.dispatch("ankauf/show", routeTo.params.id);
      next();
    }
  },
  {
    path: "/kunden",
    name: "Kunden",
    component: Kunden,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    }
  },
  {
    path: "/lagerbestand",
    name: "Lagerbestand",
    component: Lagerbestand,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    },
    async beforeEnter(routeTo, routeFrom, next) {
      await store.dispatch("lagerbestand/index");
      next();
    }
  },
  {
    path: "/berechnungen",
    name: "Berechnungen",
    component: Berechnungen,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    },
    async beforeEnter(routeTo, routeFrom, next) {
      await store.dispatch("berechnung/index");
      next();
    }
  },
  {
    path: "/berechnung/:id",
    name: "Berechnung",
    component: Berechnung,
    props: true,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    },
    async beforeEnter(routeTo, routeFrom, next) {
      await store.dispatch("berechnung/show", routeTo.params.id);
      next();
    }
  },
  {
    path: "/dokumente",
    name: "Dokumente",
    component: Dokumente,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    }
  },
  {
    path: "/einstellungen",
    name: "Einstellungen",
    component: Einstellungen,
    meta: {
      layout: LayoutApp,
      requiresAuth: true
    },
    async beforeEnter(routeTo, routeFrom, next) {
      await store.dispatch("marge/show");
      await store.dispatch("marge/index");
      next();
    }
  },
  {
    path: "/passwort-vergessen",
    component: PasswortVergessen,
    meta: {
      layout: LayoutGuest,
      requiresAuth: false
    }
  },
  {
    path: "/passwort-wiederherstellen",
    component: PasswortWiederherstellen,
    meta: {
      layout: LayoutGuest,
      requiresAuth: false
    }
  },
  {
    path: "/*",
    component: NotFound,
    meta: {
      layout: LayoutGuest,
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((routeTo, routeFrom, next) => {
  store.dispatch("menu/toggle", false);
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.auth.authenticated) {
      next();
    } else {
      store.dispatch("auth/me").then(() => {
        if (!store.state.auth.authenticated) {
          next({
            path: "/login",
            params: { nextUrl: to.fullPath }
          });
        } else {
          next();
        }
      });
    }
  } else {
    next();
  }
});

export default router;
