<template>
  <div
    class="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <SvgLogo class="w-auto h-20 mx-auto" />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          Mitarbeiter Login
        </h2>
      </div>
      <form @submit.prevent="submit" class="mt-8 space-y-6">
        <input type="hidden" name="remember" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <input
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email Adresse"
            />
          </div>
          <div>
            <input
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Passwort"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              v-model="remember"
              id="remember_me"
              name="remember_me"
              type="checkbox"
              class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <label for="remember_me" class="block ml-2 text-sm text-gray-900">
              Eingeloggt bleiben
            </label>
          </div>

          <div class="text-sm">
            <router-link
              to="/passwort-vergessen"
              class="font-medium text-brand-600 hover:text-brand-500"
            >
              Passwort vergessen?
            </router-link>
          </div>
        </div>
        <div class="text-red-600">
          <p v-if="error">
            {{ error }}
          </p>
        </div>
        <div>
          <BaseButton class="w-full" :disabled="loading">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <SvgLock class="text-brand-300" />
            </span>
            Einloggen
          </BaseButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      remember: null,
      error: null
    };
  },

  beforeMount() {
    if (this.$store.state.auth.authenticated) {
      window.location.href = "/";
    }
  },

  methods: {
    submit() {
      this.loading = true;
      this.$store
        .dispatch("auth/signIn", {
          email: this.email,
          password: this.password,
          remember: this.remember
        })
        .then(() => {
          this.$router.replace({ name: "Home" });
        })
        .catch(err => {
          this.loading = false;
          this.error = err;
        });
      this.loading = false;
    }
  }
};
</script>

<style></style>
