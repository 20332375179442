<template>
  <div>
    <BaseDashboardContainer class="mb-10">
      <template v-slot:header>
        <h4>Auszahlungen</h4>
      </template>
      <div class="-m-6 overflow-x-auto">
        <table
          class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
        >
          <thead>
            <tr class="text-left">
              <BaseTh>Medium</BaseTh>
              <BaseTh>Medium Account</BaseTh>
              <BaseTh>Betrag</BaseTh>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(auszahlung, index) in ankauf.auszahlungen"
              :key="index"
              @click="openAuszahlung(auszahlung)"
              class="cursor-pointer hover:bg-gray-50"
            >
              <BaseTd>{{ auszahlung.medium_name }}</BaseTd>
              <BaseTd
                ><p v-if="auszahlung.medium_account">
                  {{ auszahlung.medium_account }}
                </p>
                <div v-else>
                  <p>
                    {{ auszahlung.scheck_adresse.firma
                    }}{{ auszahlung.scheck_adresse.vorname }}
                    {{ auszahlung.scheck_adresse.nachname }}
                  </p>
                  <p>
                    {{ auszahlung.scheck_adresse.strasse }}
                    {{ auszahlung.scheck_adresse.hausnummer }}
                  </p>
                  <p>
                    {{ auszahlung.scheck_adresse.plz }}
                    {{ auszahlung.scheck_adresse.ort }}
                  </p>
                </div>
              </BaseTd>
              <BaseTd>{{ auszahlung.ausgezahlt | toCurrency }}</BaseTd>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-slot:footer>
        <div class="flex flex-col items-center justify-between md:flex-row">
          <p class="font-medium">
            Summe der Auszahlungen:
            {{ ankauf.auszahlungsbetrag | toCurrency }}
          </p>
          <BaseButton
            @click.native="auszahlungModal"
            class="w-full ml-auto md:w-auto"
            variant="white"
            >Transaktion hinterlegen</BaseButton
          >
        </div>
      </template>
    </BaseDashboardContainer>

    <!-- Modal Auszahlung -->
    <BaseModal :visible="showModal" @hide="hideModal">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Transaktion hinterlegen</h4>
        </template>
        <div class="grid gap-4 md:grid-cols-3">
          <div>
            <label
              for="ankauf_id"
              class="block mb-1 text-sm font-medium text-gray-600"
              >Auszahlungsbetrag</label
            >
            <BaseInput
              v-currency
              v-model="auszahlung.betrag"
              id="ankauf_id"
              class="w-full h-11"
              :class="{
                'border-red-500': $v.auszahlung.betrag.$error
              }"
            />
          </div>
          <div>
            <label
              for="auszahlungsmedium"
              class="block mb-1 text-sm font-medium text-gray-600"
              >Ausgezahlt per</label
            >
            <BaseSelect
              v-model="auszahlung.medium"
              id="auszahlungsmedium"
              class="w-full h-11"
            >
              <option value="ueberweisung">Überweisung</option>
              <option value="paypal">PayPal</option>
              <option value="scheck">Scheck</option>
            </BaseSelect>
          </div>
          <div v-if="auszahlung.medium == 'ueberweisung'">
            <label
              for="iban"
              class="block mb-1 text-sm font-medium text-gray-600"
              >IBAN</label
            >
            <BaseInput
              v-model="auszahlung.iban"
              id="iban"
              class="w-full h-11"
              :class="{
                'border-red-500': $v.auszahlung.iban.$error
              }"
            />
          </div>
          <div v-if="auszahlung.medium == 'paypal'">
            <label
              for="paypal"
              class="block mb-1 text-sm font-medium text-gray-600"
              >PayPal Adresse</label
            >
            <BaseInput
              v-model="auszahlung.paypal"
              id="paypal"
              class="w-full h-11"
              :class="{
                'border-red-500': $v.auszahlung.paypal.$error
              }"
            />
          </div>
          <div v-show="auszahlung.medium == 'scheck'" class="md:col-span-3">
            <p class="mb-2 text-gray-600">Scheck senden an:</p>
            <div v-if="ankauf.angebote[0].scheck_adresse">
              <p>
                {{ ankauf.angebote[0].scheck_adresse.firma
                }}{{ ankauf.angebote[0].scheck_adresse.vorname }}
                {{ ankauf.angebote[0].scheck_adresse.nachname }}
              </p>
              <p>
                {{ ankauf.angebote[0].scheck_adresse.strasse }}
                {{ ankauf.angebote[0].scheck_adresse.hausnummer }}
              </p>
              <p>
                {{ ankauf.angebote[0].scheck_adresse.plz }}
                {{ ankauf.angebote[0].scheck_adresse.ort }}
              </p>
            </div>
            <div v-else>
              <p>
                {{ ankauf.adresse.firma }}{{ ankauf.adresse.vorname }}
                {{ ankauf.adresse.nachname }}
              </p>
              <p>
                {{ ankauf.adresse.strasse }}
                {{ ankauf.adresse.hausnummer }}
              </p>
              <p>
                {{ ankauf.adresse.plz }}
                {{ ankauf.adresse.ort }}
              </p>
            </div>
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <BaseButton
              @click.native="auszahlungSpeichern"
              :loading="loadingAuszahlung"
              >Auszahlung speichern</BaseButton
            >
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Auszahlung ENDE -->

    <!-- Modal Auszahlung bearbeiten -->
    <BaseModal :visible="showModalBearbeiten" @hide="hideModal">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Transaktion bearbeiten</h4>
        </template>
        <div class="grid gap-6 lg:grid-cols-3">
          <div>
            <p>Auszahlungsbetrag</p>
            <p class="font-medium">
              {{ auszahlungSelected.ausgezahlt | toCurrency }}
            </p>
          </div>
          <div>
            <p>Medium</p>
            <p class="font-medium">{{ auszahlungSelected.medium_name }}</p>
          </div>
          <div>
            <p>Medium Account</p>
            <p class="font-medium">{{ auszahlungSelected.medium_account }}</p>
          </div>
        </div>
        <template v-slot:footer>
          <div v-show="!bearbeiten" class="flex justify-end">
            <BaseButton
              @click.native="bearbeiten = true"
              :loading="loadingAuszahlung"
              variant="dark"
              >Transaktion bearbeiten</BaseButton
            >
          </div>
          <div v-show="bearbeiten">
            <div
              class="flex flex-col justify-end space-x-0 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
            >
              <BaseButton
                @click.native="bearbeiten = false"
                :outline="true"
                variant="dark"
                >abbrechen</BaseButton
              >
              <BaseButton @click.native="auszahlungLoeschen" variant="danger"
                >Transaktion löschen</BaseButton
              >
            </div>
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Auszahlung bearbeiten ENDE -->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TransaktionService from "../../../services/TransaktionService.js";

export default {
  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  validations() {
    let validations = {
      auszahlung: {
        betrag: { required }
      }
    };

    if (this.auszahlung.medium == "ueberweisung") {
      validations.auszahlung.iban = {
        required
      };
    }

    if (this.auszahlung.medium == "paypal") {
      validations.auszahlung.paypal = {
        required
      };
    }

    return validations;
  },

  data() {
    return {
      showModal: false,
      loadingAuszahlung: false,
      auszahlung: {
        betrag: null,
        medium: "ueberweisung",
        iban: null,
        paypal: null
      },
      auszahlungSelected: {},
      bearbeiten: false,
      showModalBearbeiten: false
    };
  },

  created() {
    this.auszahlung.medium =
      this.ankauf.angebote[0].auszahlung_medium ?? "ueberweisung";

    if (this.auszahlung.medium == "ueberweisung") {
      this.auszahlung.iban = this.ankauf.angebote[0].medium_account;
    } else if (this.auszahlung.medium == "paypal") {
      this.auszahlung.paypal = this.ankauf.angebote[0].medium_account;
    }
  },

  methods: {
    async hideModal() {
      this.showModalBearbeiten = false;
      this.showModal = false;
      this.bearbeiten = false;
    },

    auszahlungModal() {
      this.showModal = true;
    },

    async auszahlungSpeichern() {
      this.$v.$touch();

      if (!this.$v.$anyError) {
        this.loadingAuszahlung = true;

        let medium = null;
        let scheck_adresse_id = null;

        if (this.auszahlung.medium == "ueberweisung") {
          medium = this.auszahlung.iban;
        } else if (this.auszahlung.medium == "paypal") {
          medium = this.auszahlung.paypal;
        } else {
          scheck_adresse_id = this.ankauf.angebote[0].scheck_adresse
            ? this.ankauf.angebote[0].scheck_adresse.id
            : this.ankauf.adresse.id;
        }

        await TransaktionService.auszahlung({
          ankauf_id: this.ankauf.id,
          angebot_id: this.ankauf.angebote[0].id,
          scheck_adresse_id,
          medium_account: medium,
          ...this.auszahlung
        });

        this.$store.dispatch("ankauf/show", this.ankauf.id);

        this.loadingAuszahlung = false;
        this.showModal = false;
      }
    },

    openAuszahlung(auszahlung) {
      this.auszahlungSelected = auszahlung;
      this.showModalBearbeiten = true;
    },

    async auszahlungLoeschen() {
      this.bearbeiten = false;
      this.loadingAuszahlung = true;
      await TransaktionService.loeschen(this.auszahlungSelected);

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.showModalBearbeiten = false;
      this.loadingAuszahlung = false;
    }
  }
};
</script>

<style></style>
