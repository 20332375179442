<template>
  <div class="border-t border-b">
    <div class="flex flex-wrap px-2 border-b bg-gray-50">
      <button @click="applyBold" class="EditorButton">
        <SvgBold class="w-5 h-5" />
      </button>
      <button @click="applyItalic" class="EditorButton">
        <SvgItalic class="w-5 h-5" />
      </button>
      <button @click="applyHeading" class="EditorButton">
        <SvgHeading class="w-5 h-5" />
      </button>
      <button @click="applyUl" class="EditorButton">
        <SvgUl class="w-5 h-5" />
      </button>
      <button @click="applyOl" class="EditorButton">
        <SvgOl class="w-5 h-5" />
      </button>
      <button @click="undo" class="EditorButton">
        <SvgUndo class="w-5 h-5" />
      </button>
      <button @click="redo" class="EditorButton">
        <SvgRedo class="w-5 h-5" />
      </button>
    </div>

    <div
      @input="onInput"
      v-html="innerValue"
      contenteditable="true"
      class="p-4 focus:outline-none"
    />
  </div>
</template>

<script>
import TurndownService from "turndown";

export default {
  props: {
    value: {
      type: [String],
      default: "<p><br></p>"
    }
  },

  data() {
    return {
      innerValue: this.value,
      turndown: new TurndownService({
        emDelimiter: "_",
        linkStyle: "inlined",
        headingStyle: "atx"
      })
    };
  },

  mounted() {
    document.execCommand("defaultParagraphSeparator", false, "p");
  },

  watch: {
    value: function(newVal) {
      this.innerValue = newVal;
      this.$emit("input", this.turndown.turndown(newVal));
    }
  },

  methods: {
    onInput(event) {
      this.$emit("input", this.turndown.turndown(event.target.innerHTML));
    },

    applyBold() {
      document.execCommand("bold");
    },

    applyItalic() {
      document.execCommand("italic");
    },

    applyHeading() {
      document.execCommand("formatBlock", false, "<h1>");
    },

    applyUl() {
      document.execCommand("insertUnorderedList");
    },

    applyOl() {
      document.execCommand("insertOrderedList");
    },

    undo() {
      document.execCommand("undo");
    },

    redo() {
      document.execCommand("redo");
    }
  }
};
</script>

<style scoped>
.EditorButton {
  @apply border-0 rounded-none hover:bg-gray-200 px-3 py-2;
}
</style>
