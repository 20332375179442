import apiClient from "./ApiClient.js";

export default {
  index(params = {}) {
    return apiClient.get("/berechnung", { params });
  },

  show(id) {
    return apiClient.get("/berechnung/" + id);
  }
};
