<template>
  <div>
    <BaseDashboardContainer>
      <template v-slot:header>
        <h4>Ereignisse</h4>
      </template>
      <div class="-my-4">
        <div
          v-for="(ereignis, index) in ankauf.ereignisse"
          :key="index"
          :class="{ 'border-t': index != 0 }"
          class="relative flex items-baseline py-2.5"
        >
          <div class="flex-1">
            <p class="text-xs text-gray-600">{{ ereignis.datum }} Uhr</p>
            <p class="text-sm font-medium text-gray-800">
              {{
                ereignis.ereignis_typ
                  ? ereignis.ereignis_typ.titel
                  : ereignis.titel
              }}
            </p>
            <p v-if="ereignis.beschreibung" class="text-sm text-gray-600">
              {{ ereignis.beschreibung }}
            </p>
          </div>
          <div class="flex-shrink-0 text-sm text-right">
            <p v-if="ereignis.mitarbeiter" class="text-brand-600">
              <SvgUser class="inline w-4 h-4 mr-1" />{{
                ereignis.mitarbeiter.vorname
              }}
            </p>
            <p
              v-if="!ereignis.ereignis_typ"
              @click="openEreignis(ereignis)"
              class="cursor-pointer"
            >
              bearbeiten
            </p>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <BaseButton
          @click.native="ereignisAnlegen"
          class="ml-auto"
          variant="white"
          :small="true"
          ><SvgInfo class="inline w-4 h-4 mr-2" />Ereignis erstellen</BaseButton
        >
      </template>
    </BaseDashboardContainer>

    <!-- Modal Ereignis hinterlegen -->
    <BaseModal :visible="showModal" @hide="hideModal">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Ereignis hinterlegen</h4>
        </template>
        <div class="py-20">
          <div class="grid gap-4 mb-4 md:grid-cols-2">
            <div>
              <label
                for="titel_edit"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Titel</label
              >
              <BaseInput
                v-model="ereignis.titel"
                id="titel_edit"
                type="text"
                maxlength="30"
                class="w-full h-11"
                :class="{
                  'border-red-500': $v.ereignis.titel.$error
                }"
              />
            </div>
            <div>
              <label
                for="zeitpunkt"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Zeitpunkt</label
              >
              <Datetime
                v-model="ereignis.zeitpunkt"
                type="datetime"
                format="dd.MM.yyyy HH:mm:ss"
                :hide-backdrop="true"
                :phrases="{ ok: 'Ok', cancel: 'Abbrechen' }"
                class="base-input h-11"
              ></Datetime>
            </div>
          </div>
          <div>
            <label
              for="beschreibung"
              class="block mb-1 text-sm font-medium text-gray-600"
              >Beschreibung <span class="text-sm">(optional)</span></label
            >
            <BaseTextarea
              v-model="ereignis.beschreibung"
              id="beschreibung"
              class="w-full"
            />
          </div>
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <BaseButton
              @click.native="ereignisSpeichern"
              :loading="loadingEreignis"
              >Ereignis speichern</BaseButton
            >
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Ereignis hinterlegen ENDE -->

    <!-- Modal Ereignis bearbeiten -->
    <BaseModal :visible="showModalEreignis" @hide="hideModal">
      <BaseDashboardContainer>
        <template v-slot:header>
          <h4>Ereignis bearbeiten</h4>
        </template>
        <div>
          <div v-show="!bearbeiten">
            <h5 class="mb-4">{{ ereignis.titel }}</h5>
            <p class="text-gray-600">{{ ereignis.beschreibung }}</p>
          </div>
          <div v-show="bearbeiten" class="py-20">
            <div class="grid gap-4 mb-4 md:grid-cols-2">
              <div>
                <label
                  for="titel"
                  class="block mb-1 text-sm font-medium text-gray-600"
                  >Titel</label
                >
                <BaseInput
                  v-model="ereignis.titel"
                  id="titel"
                  type="text"
                  maxlength="30"
                  class="w-full h-11"
                  :class="{
                    'border-red-500': $v.ereignis.titel.$error
                  }"
                />
              </div>
              <div>
                <label
                  for="zeitpunkt"
                  class="block mb-1 text-sm font-medium text-gray-600"
                  >Zeitpunkt</label
                >
                <Datetime
                  v-model="ereignis.zeitpunkt"
                  type="datetime"
                  format="dd.MM.yyyy HH:mm:ss"
                  :hide-backdrop="true"
                  class="base-input h-11"
                ></Datetime>
              </div>
            </div>
            <div>
              <label
                for="beschreibung"
                class="block mb-1 text-sm font-medium text-gray-600"
                >Beschreibung <span class="text-sm">(optional)</span></label
              >
              <BaseTextarea
                v-model="ereignis.beschreibung"
                id="beschreibung"
                class="w-full"
              />
            </div>
          </div>
        </div>
        <template v-slot:footer>
          <div v-show="!bearbeiten" class="flex justify-end">
            <BaseButton
              @click.native="bearbeiten = true"
              :loading="loadingEreignis"
              variant="dark"
              >Ereignis bearbeiten</BaseButton
            >
          </div>
          <div
            v-show="bearbeiten"
            class="flex flex-col justify-between md:flex-row"
          >
            <BaseButton
              @click.native="ereignisLoeschen"
              variant="danger"
              class="mb-3 md:mb-0"
              >Ereignis löschen</BaseButton
            >
            <div
              class="flex flex-col space-x-0 space-y-3 md:flex-row md:space-y-0 md:space-x-4"
            >
              <BaseButton
                @click.native="bearbeiten = false"
                :outline="true"
                variant="dark"
                >abbrechen</BaseButton
              >
              <BaseButton @click.native="ereignisBearbeiten" variant="dark"
                >Änderungen speichern</BaseButton
              >
            </div>
          </div>
        </template>
      </BaseDashboardContainer>
    </BaseModal>
    <!-- Modal Ereignis bearbeiten ENDE -->
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import { required } from "vuelidate/lib/validators";
import AnkaufEreignisService from "../../services/AnkaufEreignisService.js";

export default {
  components: {
    Datetime
  },

  props: {
    ankauf: {
      type: Object,
      required: true
    }
  },

  validations: {
    ereignis: {
      titel: { required },
      zeitpunkt: { required }
    }
  },

  data() {
    return {
      showModal: false,
      loadingEreignis: false,
      ereignis: {
        titel: null,
        beschreibung: null,
        zeitpunkt: null
      },
      bearbeiten: false,
      showModalEreignis: false
    };
  },

  methods: {
    hideModal() {
      this.showModal = false;
      this.showModalEreignis = false;
      this.bearbeiten = false;
    },

    ereignisAnlegen() {
      const date = new Date();
      this.ereignis.zeitpunkt =
        date.toISOString().split("T")[0] +
        "T" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds() +
        "+01:00";
      this.showModal = true;
    },

    async ereignisSpeichern() {
      this.loadingEreignis = true;
      await AnkaufEreignisService.eigenes({
        ankauf_id: this.ankauf.id,
        ...this.ereignis
      });

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loadingEreignis = false;
      this.showModal = false;
    },

    openEreignis(ereignis) {
      if (!ereignis.ereignis_typ) {
        this.ereignis = ereignis;

        this.ereignis.zeitpunkt = this.ereignis.zeitpunktFormatted;
        this.showModalEreignis = true;
      }
    },

    async ereignisBearbeiten() {
      this.bearbeiten = false;
      this.loadingEreignis = true;
      await AnkaufEreignisService.bearbeiten({
        ...this.ereignis
      });

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loadingEreignis = false;
      this.showModalEreignis = false;
    },

    async ereignisLoeschen() {
      this.bearbeiten = false;
      this.loadingEreignis = true;
      await AnkaufEreignisService.loeschen({
        ...this.ereignis
      });

      this.$store.dispatch("ankauf/show", this.ankauf.id);
      this.loadingEreignis = false;
      this.showModalEreignis = false;
    }
  }
};
</script>

<style></style>
