<template>
  <BaseDashboardContainer class="mt-10">
    <template v-slot:header>
      <h4>Berechnungen</h4>
    </template>
  </BaseDashboardContainer>
</template>

<script>
export default {};
</script>

<style></style>
