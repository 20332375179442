import AnkaufService from "../../services/AnkaufService.js";

export const namespaced = true;

export const state = {
  ankaeufe: null,
  ankauf: null
};

export const mutations = {
  SET_ANKAEUFE(state, ankaeufe) {
    state.ankaeufe = ankaeufe;
  },
  SET_ANKAUF(state, ankauf) {
    state.ankauf = ankauf;
  }
};

export const actions = {
  async index({ commit }, params) {
    const resp = await AnkaufService.getAnkaeufe(params);
    commit("SET_ANKAEUFE", resp.data);
  },
  async show({ commit }, id) {
    const resp = await AnkaufService.getAnkauf(id);
    commit("SET_ANKAUF", resp.data);
  }
};
