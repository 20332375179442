<template>
  <div class="fixed bottom-0 right-0 z-50 sm:bottom-10 sm:right-10">
    <TransitionFade :group="true">
      <Notification
        v-for="(notification, index) in notifications"
        :key="index"
        :notification="notification"
      />
    </TransitionFade>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Notification from "./Notification.vue";
import TransitionFade from "../Transitions/TransitionFade.vue";

export default {
  components: {
    Notification,
    TransitionFade
  },

  computed: mapState({
    notifications: state => state.notification.notifications
  })
};
</script>

<style></style>
