<template>
  <div
    class="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <SvgLogo class="w-auto h-20 mx-auto" />
        <h2 class="mt-6 text-3xl font-extrabold text-center text-gray-900">
          Passwort wiederherstellen
        </h2>
        <form @submit.prevent="submit" class="mt-8 space-y-6">
          <BaseInput
            v-model="email"
            name="email"
            type="email"
            autocomplete="email"
            required
            placeholder="Email Adresse"
            class="w-full h-11"
          />

          <div class="text-red-600">
            <p v-if="error">
              {{ error }}
            </p>
          </div>
          <div>
            <BaseButton class="w-full" :disabled="loading">
              Passwort wiederherstellen
            </BaseButton>
          </div>
        </form>
        <p class="mt-5 text-sm font-medium text-gray-600">
          <router-link to="/login">zurück zum Login</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/AuthService.js";

export default {
  data() {
    return {
      loading: false,
      email: null,
      error: null
    };
  },

  methods: {
    submit() {
      this.loading = true;
      AuthService.vergessen({
        email: this.email,
        mitarbeiter: true
      }).catch(err => {
        this.loading = false;
        this.error = err;
      });
      this.loading = false;
    }
  }
};
</script>

<style></style>
