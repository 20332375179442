<template>
  <div class="pb-10">
    <div class="border-b">
      <BaseContainer>
        <div class="flex flex-wrap justify-between">
          <div class="flex flex-col justify-center">
            <p class="font-medium">Berechnung</p>
          </div>
        </div>
      </BaseContainer>
    </div>
    <BaseContainer>
      <div class="p-4 mb-6 -m-4">
        <table class="table-auto">
          <tbody>
            <tr>
              <td class="font-normal text-gray-600 align-top w-28">
                <h5>Rechner ID:</h5>
              </td>
              <td>
                <h5>{{ berechnung.rechner_id }}</h5>
              </td>
            </tr>
            <tr>
              <td class="text-gray-600">Datum:</td>
              <td>{{ berechnung.datum }} Uhr</td>
            </tr>
            <tr>
              <td class="text-gray-600">Heruntergeladen:</td>
              <td>
                <SvgClose
                  v-if="!berechnung.download"
                  class="text-red-600 w-7 h-7"
                /><SvgCheck v-else class="text-green-600 w-7 h-7" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <BaseDashboardContainer class="mb-10">
        <template v-slot:header>
          <div class="flex justify-between">
            <h4>Gegenstände</h4>
          </div>
        </template>
        <div class="-mx-6 -mt-10 overflow-x-auto">
          <table
            class="relative w-full my-4 whitespace-no-wrap border-b border-collapse table-auto table-striped"
          >
            <thead>
              <tr class="text-left">
                <BaseTh>Gegenstand</BaseTh>
                <BaseTh>Gewicht</BaseTh>
                <BaseTh>Edelmetall</BaseTh>
                <BaseTh>Legierung</BaseTh>
                <BaseTh>Börsenkurs</BaseTh>
                <BaseTh>Marge</BaseTh>
                <BaseTh>Auszahlungsbetrag</BaseTh>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(gegenstand, index) in berechnung.ergebnis.gegenstände"
                :key="index"
              >
                <BaseTd>{{ gegenstand.gegenstand }}</BaseTd>
                <BaseTd>{{ gegenstand.gewicht }}g</BaseTd>
                <BaseTd>{{ gegenstand.edelmetall | ucfirst }}</BaseTd>
                <BaseTd>{{ gegenstand.legierung }}</BaseTd>
                <BaseTd>{{ gegenstand.boersenkurs | toCurrency }}</BaseTd>
                <BaseTd>{{ gegenstand.marge | toCurrency }}</BaseTd>
                <BaseTd>{{ gegenstand.auszahlungsbetrag | toCurrency }}</BaseTd>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p class="text-sm text-gray-600">
            Börsenwert: {{ berechnung.ergebnis.boersenkurs | toCurrency }}
          </p>
          <p class="text-sm text-gray-600">
            Handelsmarge: {{ berechnung.ergebnis.marge | toCurrency }}
          </p>
          <p class="text-sm text-gray-600">
            Versandkosten:
            {{ berechnung.ergebnis.versandkosten | toCurrency }}
          </p>
          <p class="relative mt-2 text-lg font-semibold">
            Auszahlungsbetrag:
            {{ berechnung.ergebnis.auszahlungsbetrag | toCurrency }}
          </p>
        </div>
      </BaseDashboardContainer>
      <BaseDashboardContainer class="mb-10">
        <template v-slot:header>
          <div class="flex justify-between">
            <h4>Börsenkurse</h4>
          </div>
        </template>
        <div class="-mx-6 -my-10 overflow-x-auto">
          <table
            class="relative w-full my-4 whitespace-no-wrap border-b border-collapse table-auto table-striped"
          >
            <thead>
              <tr class="text-left">
                <BaseTh>Edelmetall</BaseTh>
                <BaseTh>Preis pro Gramm</BaseTh>
                <BaseTh>Preis pro Unze</BaseTh>
                <BaseTh>Preis pro Kilogramm</BaseTh>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(edelmetall, index) in [
                  'gold',
                  'silber',
                  'platin',
                  'palladium'
                ]"
                :key="index"
              >
                <BaseTd>{{ edelmetall | ucfirst }}</BaseTd>
                <BaseTd>{{
                  berechnung.boersenkurs[edelmetall + "_eur_gramm"] | toCurrency
                }}</BaseTd>
                <BaseTd>{{
                  berechnung.boersenkurs[edelmetall + "_eur_unze"] | toCurrency
                }}</BaseTd>
                <BaseTd>{{
                  berechnung.boersenkurs[edelmetall + "_eur_kilogramm"]
                    | toCurrency
                }}</BaseTd>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseDashboardContainer>
      <BaseDashboardContainer class="mb-10">
        <template v-slot:header>
          <div class="flex justify-between">
            <h4>Marge</h4>
          </div>
        </template>
        <div class="-m-6 overflow-x-auto">
          <table
            class="relative w-full whitespace-no-wrap border-b border-collapse table-auto table-striped"
          >
            <tr>
              <BaseTh></BaseTh>
              <BaseTh class="text-left">Gold</BaseTh>
              <BaseTh class="text-left">Silber</BaseTh>
              <BaseTh class="text-left">Platin</BaseTh>
              <BaseTh class="text-left">Palladium</BaseTh>
            </tr>
            <tr>
              <BaseTh class="text-left">Handelsfähig</BaseTh>
              <BaseTd>{{ berechnung.marge.gold_handelsfaehig }} %</BaseTd>
              <BaseTd>{{ berechnung.marge.silber_handelsfaehig }} %</BaseTd>
              <BaseTd>{{ berechnung.marge.platin_handelsfaehig }} %</BaseTd>
              <BaseTd>{{ berechnung.marge.palladium_handelsfaehig }} %</BaseTd>
            </tr>
            <tr>
              <BaseTh class="text-left">Nicht Handelsfähig</BaseTh>
              <BaseTd>{{ berechnung.marge.gold }} %</BaseTd>
              <BaseTd>{{ berechnung.marge.silber }} %</BaseTd>
              <BaseTd>{{ berechnung.marge.platin }} %</BaseTd>
              <BaseTd>{{ berechnung.marge.palladium }} %</BaseTd>
            </tr>
          </table>
        </div>
      </BaseDashboardContainer>
    </BaseContainer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  computed: {
    ...mapState({
      berechnung: state => state.berechnung.berechnung
    })
  }
};
</script>

<style></style>
